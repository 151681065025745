import { MadeByYouDetailComponent } from './landing/made-by-you-detail/made-by-you-detail.component';
import { MadeByYouStoriesComponent } from './landing/made-by-you-stories/made-by-you-stories.component';
import { VisitUsNewComponent } from './shared/visit-us-new/visit-us-new.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApartmentGalleryComponent } from './apartment/apartment-gallery/apartment-gallery.component';
import { ApartmentLandingComponent } from './apartment/apartment-landing/apartment-landing.component';
import { ApartmentSearchComponent } from './apartment/apartment-search/apartment-search.component';
import { UpdatedApartmentPageComponent } from './apartment/updated-apartment-page/updated-apartment-page.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { KitchenGuideComponent } from './landing/kitchen-guide/kitchen-guide.component';
import { LandingPageNewComponent } from './landing/landing-page-new/landing-page-new.component';
import { PaymentRefreshComponent } from './landing/payment-refresh/payment-refresh.component';
import { PrivacyPolicyComponent } from './landing/privacy-policy/privacy-policy.component';
import { RustomjeeComponent } from './landing/rustomjee/rustomjee.component';
import { SignUpToServiceComponent } from './landing/sign-up-to-service/sign-up-to-service.component';
import { TermsAndConditionsComponent } from './landing/terms-and-conditions/terms-and-conditions.component';
import { TermsOfUseComponent } from './landing/terms-of-use/terms-of-use.component';
import { LifestageComponent } from './lifestage/lifestage/lifestage.component';
import { MagicLinkComponent } from './magic-link/magic-link.component';
import { BecomeDesignPartnerComponent } from './shared/become-design-partner/become-design-partner.component';
import { ButtonPrevComponent } from './shared/button-prev/button-prev.component';
import { ButtonComponent } from './shared/button/button.component';
import { GetEstimatePackageComponent } from './shared/get-estimate-package/get-estimate-package.component';
import { GetFinanceComponent } from './shared/get-finance/get-finance.component';
import { GetStoreComponent } from './shared/get-store/get-store.component';
import { GetYourEstimateComponent } from './shared/get-your-estimate/get-your-estimate.component';
import { SpaceInclusionComponent } from './spaces/space-inclusion/space-inclusion.component';
import { SpacesComponent } from './spaces/spaces/spaces.component';
import { UpdatedSpacesPageComponent } from './spaces/updated-spaces-page/updated-spaces-page.component';
import { ThemeComponent } from './themes/theme/theme.component';
import { UpdatedApartmentComponent } from './apartment/updated-apartment/updated-apartment.component';
import { TestingMapComponent } from './shared/testing-map/testing-map.component';
import { AboutUsComponent } from './landing/about-us/about-us.component';
import { CustomerBoqComponent } from './customer-boq/customer-boq.component';
import { CustomerDashbWhatsappComponent } from './customer-dashb-whatsapp/customer-dashb-whatsapp.component';
import { ChequePaymentComponent } from './cheque-payment/cheque-payment.component';
import { EscalationTableComponent } from './shared/escalation-table/escalation-table.component';
import { NocTableComponent } from './shared/noc-table/noc-table.component';
import { GetQuoteLandingPageComponent } from './get-quote-landing-page/get-quote-landing-page.component';



import { MarathonNexajoneComponent } from './marathon-nexajone/marathon-nexajone.component';
import { DesignThemesComponent } from './landing/design-themes/design-themes.component';
import { ArrivaeLivingLandingComponent } from './landing/arrivae-living-landing/arrivae-living-landing.component';
import { TestimonialComponent } from './landing/testimonial/testimonial.component';
import { SpacesListingComponent } from './spaces/spaces-listing/spaces-listing.component';
import { VRlinksComponent } from './vrlinks/vrlinks.component';
import { ReferAFriendComponent } from './landing/refer-a-friend/refer-a-friend.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ReviewsComponent } from './landing/reviews/reviews.component';
import { ReviewDetailComponent } from './landing/review-detail/review-detail.component';
import { PromoterProgramComponent } from './promoter-program/promoter-program.component';
import {DesignWithUsComponent } from './landing/design-with-us/design-with-us.component'

const routes: Routes = [
  { path: '', component:ArrivaeLivingLandingComponent},
  { path: 'new-landing', component:ArrivaeLivingLandingComponent},
  {path:'get-home-interior-designs',component:GetQuoteLandingPageComponent},
  {path:'get-home-interior-designs/f',component:GetQuoteLandingPageComponent},
  {path:'get-home-interior-designs/g',component:GetQuoteLandingPageComponent},
  { path: 'space-inclusion/:id', component: SpaceInclusionComponent },
  { path: 'apartment-search', component: ApartmentLandingComponent },
  { path: 'kitchen-guide', component: KitchenGuideComponent },
  { path: 'lifestage', component: LifestageComponent },
  { path: 'button', component: ButtonComponent },
  { path: 'button-prev', component: ButtonPrevComponent },
  { path: 'themes', component: ThemeComponent },
  { path: 'terms-and-condition', component: TermsOfUseComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'get-your-estimate', component: GetYourEstimateComponent },
  { path: 'get-estimate-package', component: GetEstimatePackageComponent },
  { path: 'get-finance', component: GetFinanceComponent },
  { path: 'visit-us', component: VisitUsNewComponent },
  { path: 'become-design-partner', component: BecomeDesignPartnerComponent },
  { path: 'get-store', component: GetStoreComponent },
  { path: 'customer_cheque_payment', component:  ChequePaymentComponent },
  { path: 'payment-process', component: PaymentRefreshComponent },
  { path: 'customer_magic_login', component: MagicLinkComponent },
  { path: 'customer-dashboard', component: MagicLinkComponent },
  { path: 'rustomjee', component: RustomjeeComponent },
  { path: 'new-landing-page-updated', component: LandingPageNewComponent },
  { path: 'spaces', component: UpdatedSpacesPageComponent },
  { path: 'about-us', component: AboutUsComponent },
  // { path: 'old-spaces', component: SpacesComponent },
  { path: 'spaces/listing', component: SpacesListingComponent },
  { path: 'vrlinks', component: VRlinksComponent },
  // { path: 'old-spaces', component: SpacesComponent },
  { path: 'new-spaces-page', component: UpdatedSpacesPageComponent },
  { path:  'arrivae-living', component: ArrivaeLivingLandingComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'design-with-us', component:DesignWithUsComponent},
  { path: 'get-interior-design', component: SignUpToServiceComponent },

  { path: 'testimonial', component: TestimonialComponent },
  { path: 'apartment', component: UpdatedApartmentPageComponent },
  { path: 'apartment-detail', component: UpdatedApartmentComponent },
  { path: 'made-by-you-story', component: MadeByYouStoriesComponent },
  { path: 'made-by-you-detail', component: MadeByYouDetailComponent },
  { path: 'testing-map11', component: TestingMapComponent },
  { path: 'customer_magic_login_web_boq', component:  CustomerBoqComponent },
  { path: 'customer-dashboard-whatsapp', component: CustomerDashbWhatsappComponent },
  { path: 'escalation', component: EscalationTableComponent },
  { path: 'noc', component: NocTableComponent },
  { path: 'marathon-nexzone', component: MarathonNexajoneComponent},
  { path: '25-south', component: MarathonNexajoneComponent},
  { path: 'anantraj-villa', component: MarathonNexajoneComponent},
  {path:'southern-crest', component:MarathonNexajoneComponent},
  {path:'sky-forest',component:MarathonNexajoneComponent},
  {path:'m3m-skywalk',component:MarathonNexajoneComponent},
  {path:'Tridhaatu-Morya', component:MarathonNexajoneComponent},
  {path:'Hubtown_Seasons', component:MarathonNexajoneComponent},
  {path:'Hubtown_Heaven', component:MarathonNexajoneComponent},
  {path:'Hubtown_Harmony', component:MarathonNexajoneComponent},
  {path:'140-shivaji', component:MarathonNexajoneComponent},
  {path:'Tridhaatu-Aranya', component:MarathonNexajoneComponent},
  {path:'adhiraj-capital-city', component:MarathonNexajoneComponent},
  {path:'Runwal_Gardens', component:MarathonNexajoneComponent},
  {path:'dosti-pine', component:MarathonNexajoneComponent},
  {path:'lodha-amara', component:MarathonNexajoneComponent},
  {path:'Birla-Alokya', component:MarathonNexajoneComponent},
  {path:'Rustomjee-Summit', component:MarathonNexajoneComponent},
  {path:'Email-Verification', component:EmailVerificationComponent},
  {path:'Reviews', component:ReviewsComponent},
  {path:'refer', component:ReferAFriendComponent},
  {path:'review/detail', component:ReviewDetailComponent},
  {path:'evangelists', component:PromoterProgramComponent},

  { path: '**', component: ErrorpageComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes , { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
