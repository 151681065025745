import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingService } from '../landing.service';
import { ToastrService } from 'ngx-toastr';
import { Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';
import { ApartmentService } from 'src/app/apartment/apartment.service';
declare var $: any;

@Component({
  selector: 'app-arrivae-living-landing',
  templateUrl: './arrivae-living-landing.component.html',
  styleUrls: ['./arrivae-living-landing.component.scss'],
})
export class ArrivaeLivingLandingComponent implements OnInit {
  selectedCity: any;
  cityData: any;
  citiesData: any;
  city_id: any;
  points: any;
  experience: any;
  multiCenters: any;
  singleCenters: any;
  tokenForMagic: any;
  loader: any;
  accessToken: any;
  url: any;
  BookingDetails: any = FormGroup;
  consultation: any = [
    {
      image: '../../../assets/images/design consultation/furbishing.png',
      tag: 'Customization',
      quote: 'Your Dream Home, Your Way',
      padding: 'padding:0px',
      width: 'width:154px',
    },
    {
      image: '../../../assets/images/design consultation/loan.png',
      tag: 'Affordability',
      quote: 'No-Cost, easy EMIs',
      padding: 'padding:0px 31px',
      width: 'width:93px',
    },
    {
      image: '../../../assets/images/design consultation/best-seller.png',
      tag: 'Exclusive',
      quote: 'Italian designs at Indian prices',
      padding: 'padding:0px 23px',
      width: 'width:119px',
    },
    {
      image: '../../../assets/images/design consultation/vehicle.png',
      tag: 'One-Stop Solutions',
      quote: 'End-to-end Services',
      padding: 'padding:0px 15px',
      width: 'width:115px',
    },
    {
      image: '../../../assets/images/design consultation/shipped.png',
      tag: 'Quick Delivery',
      quote: '45-Day Guarantee',
      padding: 'padding:0px 25px',
      width: 'width:115px',
    },
    {
      image: '../../../assets/images/design consultation/interior design.png',
      tag: 'Form & Function',
      quote: 'Stylish and space-saving',
      padding: 'padding:0px 20px',
      width: 'width:115px',
    },
  ];
  carouselImages: any = [
    {
      url: '../../../assets/images/arrivaeHomePageImages/Banner 1.png',
      alt: 'Arrivae Banner 01',
      redirectUrl: 'https://web.arrivae.com/space-inclusion/1248',
    },
  ];
  mobilecaroselImages: any = [
    {
      url: '../../../assets/images/arrivaeHomePageImages/Banner 1.png',
      alt: 'Arrivae Banner 01',
      redirectUrl: 'https://web.arrivae.com/space-inclusion/1248',
    },
  ];

  mobileNumber: any;
  leadInvitationId: any;
  enteredOptNumber: any;

  constructor(
    private landingService: LandingService,
    private router: Router,
    private toastr: ToastrService,
    private metaService: Meta,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private apartmentservice: ApartmentService,
    private activatedRoute: ActivatedRoute,
    private _location: Location
  ) {}
  showgoogle: any = false;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (window.location.href.includes('Made-By-You')) {
      setTimeout(() => {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $('.Made-By-You').offset().top - 100,
            behavior: 'smooth',
          },
          200
        );
      }, 2000);
      this._location.go('/');
    }

    $('.numberCountUp').counterUp({ delay: 10, time: 1000 });
    this.url = localStorage.getItem('loginuser');
    this.accessToken = localStorage.getItem('access-token')
      ? localStorage.getItem('access-token')
      : '';
    localStorage.setItem('logintoken', this.url);
    let tokenMagic = this.url.split('=');
    this.tokenForMagic = tokenMagic[1];
    this.fetchPoints();
    this.magicLogin();

    this.animateIntroText();
    this.animateStatsPanel();
    this.createForm();

    $('#getQuoteModal').on('hide.bs.modal', function () {
      if ($('#get-quote-container').hasClass('get-quote-container-show')) {
        $('#get-quote-container').removeClass('get-quote-container-show');
        $('#get-quote-container').modal('hide');
      }
    });
    //   $([document.documentElement, document.body]).animate({
    //     scrollTop: $(".how-it-works23").offset().top
    // }, 200);
    this.metaService.addTag({
      name: 'description',
      content:
        'End-to-end Full Home Interior Solutions & India’s first Made-To-Order Furniture Manufacturing Company. PAN India Presence in 21 Cities | 5 Experience Centers | 3 Manufacturing Units | 35 Experience Points | Robust Aftersales Support',
    });
    this.loadScript();
  }
  cname: any;

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
    let node1 = document.createElement('script');
    node1.src = '../../assets/newjs/slider.js';
    node1.async = true;
    document.getElementsByTagName('head')[0].appendChild(node1);
  }

  ngOnDestroy() {
    this.metaService.removeTag("name='description'");
  }
  ngAfterViewInit() {
    // let google = document.createElement('script');
    // google.src =  "https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=AIzaSyCaKbVhcX_22R_pRKDYuNA7vox-PtGaDkI";
    // google.async = true;
    // google.defer = true
    // document.getElementsByTagName('head')[0].appendChild(google);
  }

  magicLogin() {
    this.landingService.Magiclogin(this.tokenForMagic).subscribe((res) => {
      this.cname = res;
      this.loader = true;
      setTimeout(() => {
        this, (this.loader = false);
        this.router.navigate(['/customer-dashboard']);
      }, 4000);

      localStorage.setItem('cname', this.cname.user.name);
    });
  }

  fetchPoints() {
    if (localStorage.getItem('selectedCity') == null) {
      this.selectedCity = 'mumbai';
    } else {
      this.selectedCity = localStorage.getItem('selectedCity');
    }
    this.landingService.getCities().subscribe((data) => {
      this.cityData = data;
      this.citiesData = this.cityData.data;
      for (let ct of this.citiesData) {
        if (ct.attributes.name == this.selectedCity) {
          this.city_id = ct.id;
        }
      }

      this.landingService.getpoints(this.city_id).subscribe((res) => {
        if (res) {
          this.points = res;
          this.experience = this.points.data;
          if (this.experience.length > 1) {
            this.multiCenters = true;
            this.singleCenters = false;
          }
          if (this.experience.length == 1) {
            this.singleCenters = true;
            this.multiCenters = false;
          }
        }
      });
    });
  }

  customFunc(data: any) {
    this.selectedCity = data.selectedCity;
    this.fetchPoints();
  }

  animateIntroText() {
    setTimeout(() => {
      $('#intro-text').addClass('intro-text-show');
    }, 500);
  }

  animateStatsPanel() {
    setTimeout(() => {
      $('#stats-panel').addClass('stats-panel-show');
    }, 500);
  }

  quoteModalShowHide() {
    if ($('#get-quote-container').hasClass('get-quote-container-show')) {
      $('#getQuoteModal').modal('hide');
      $('#get-quote-container').removeClass('get-quote-container-show');
    } else {
      $('#getQuoteModal').modal('show');
      $('#get-quote-container').addClass('get-quote-container-show');
    }
  }

  namephn: any;
  otpVerification() {
    var regexPhoneNo = /^\+?([0-9]{2})?[0-9]{10}$/;
    if (this.mobileNumber && this.mobileNumber.match(regexPhoneNo)) {
      // get last 10 character before sending
      this.landingService.optGeneration(this.mobileNumber.slice(-10)).subscribe(
        (res: any) => {
          if (res) {
            this.namephn = this.mobileNumber;
            this.leadInvitationId = res.data.lead_invitation_id;
            this.toastr.success(res.data.message);
            this.mobileNumber = '';
            this.quoteModalShowHide();
          }
        },
        (err) => {
          this.toastr.error(err.error.data.message);
        }
      );
    } else {
      this.toastr.error('Please Enter valid mobile number.');
    }
  }

  boolvalue: boolean = false;
  l_lead_id: any;
  verifyOtp() {
    this.landingService
      .optVerification(this.enteredOptNumber, this.leadInvitationId)
      .subscribe(
        (res: any) => {
          if (res) {
            this.boolvalue = true;
            this.l_lead_id = res.data.lead_id;
            $('#getQuoteModal').modal('hide');
            this.toastr.success(res.data.message);
            this.enteredOptNumber = '';
          }
        },
        (err: any) => {
          this.toastr.error(err.error.data.message);
          this.enteredOptNumber = '';
        }
      );
  }
  bookConsultationLandingPage() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
    if (modal.componentInstance) {
      modal.componentInstance.page = 'get_the_price';
    }
  }
  roletoSet() {
    var width = Math.max(window.screen.width, window.innerWidth);
    return width;
  }
  fetchclass(e: any) {
    console.log(e);
    let colheight;
    if (this.roletoSet() < 650) {
      colheight = 800;
    } else {
      colheight = 100;
    }
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $('.' + e).offset().top - colheight,
        behavior: 'smooth',
      },
      200
    );
  }
  createForm() {
    this.BookingDetails = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required, Validators.minLength(10)]],
      city: [''],
      location: [''],
      pincode: [''],
      property_name: [''],
      apartment: ['', Validators.required],
    });
  }
  formattedaddress = ' ';
  PropertyName: any;
  Location: any;

  handleAddressChange(address: any) {
    this.PropertyName = address.name;
    this.FullAdress2 = this.PropertyName;
    this.formattedaddress = address.name;

    this.formattedaddress = address.name + ',' + address.formatted_address;
    var str = this.formattedaddress;
    var arr = [];
    arr = str.split(',');
    var unique = [];
    for (i = 0; i < arr.length; i++) {
      if (
        i == arr.indexOf(arr[i]) ||
        arr.indexOf(arr[i]) == arr.lastIndexOf(arr[i])
      )
        unique.push(arr[i]);
    }
    this.formattedaddress = unique.join(',');

    console.log(this.formattedaddress);
    console.log(address);
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == 'postal_code') {
          console.log(address.address_components[i].long_name);

          this.pincodePro2 = address.address_components[i].long_name;
        } else {
          this.pincodePro2 = '';
        }

        this.FullAdress2 = this.formattedaddress;
        var areaName = address.address_components.filter((component: any) =>
          component.types.includes('sublocality')
        );
        const concatenatedNames = areaName
          .map((component: any) => component.long_name)
          .join(', ');
        areaName = concatenatedNames;
        this.areaname = areaName ? areaName : this.formattedaddress;
        if (address.address_components[i].types[j] == 'locality') {
          this.BookingDetails.controls['city'].setValue(
            address.address_components[i].long_name
          );
          this.citynamePro2 = address.address_components[i].long_name;
          console.log(this.BookingDetails.controls['city'].value);
          console.log(address.address_components[i].long_name);
        }
      }
    }
  }
  checkDisable() {
    if (this.BookingDetails.invalid) {
      return true;
    } else {
      return false;
    }
  }
  AidApartmentID: any;
  DetailsSubmit() {
    this.loader = true;
    // const postObj = {
    //   lead: {
    //     name: this.BookingDetails.value.name, -
    //     contact: this.BookingDetails.value.contact, -
    //     lead_source: 'website', -
    //     lead_campaign: 'Homepage', -
    //     lead_utm_medium:'', -
    //     lead_utm_term: '', -
    //     lead_utm_content: '', -
    //     email: this.BookingDetails.value.email, -
    //     scope_of_work:'', -
    //     city: this.BookingDetails.value.city, -
    //     pincode: this.BookingDetails.value.pincode, -
    //     location: this.BookingDetails.value.apartment, -
    //     property_name:  this.BookingDetails.value.property_name, -
    //     additional_comments:'',
    //     aide_apartment_id:this.AidApartmentID,
    //     marketing_page:true
    //   }
    // };

    const formattedData: any = {
      leads: [
        { Attribute: 'EmailAddress', Value: this.BookingDetails.value.email },
        { Attribute: 'FirstName', Value: this.BookingDetails.value.name },
        { Attribute: 'mx_Lead_Name', Value: this.BookingDetails.value.name },
        { Attribute: 'Phone', Value: this.BookingDetails.value.contact },
        {
          Attribute: 'mx_Location',
          Value: this.BookingDetails.value.property_name,
        },
        {
          Attribute: 'mx_Society_Name',
          Value: this.BookingDetails.value.apartment,
        },
        { Attribute: 'mx_City', Value: this.BookingDetails.value.city },
        { Attribute: 'mx_Zip', Value: this.BookingDetails.value.pincode },
        { Attribute: 'Source', Value: 'website' },
        { Attribute: 'SourceCampaign', Value: 'Homepage' },
        { Attribute: 'mx_UTM_Medium', Value: '' },
        { Attribute: 'mx_UTM_Term', Value: '' },
        { Attribute: 'mx_UTM_Content', Value: '' },
        { Attribute: 'mx_Scope_of_Work', Value: '' },
        { Attribute: 'mx_Is_MarketingPage', Value: true },
        { Attribute: 'mx_Apartment_ID', Value: this.AidApartmentID },

        // { "Attribute": "additional_comments", "Value": '' },
        // { "Attribute": "aide_apartment_id", "Value": this.AidApartmentID },
        // { "Attribute": "marketing_page", "Value": true }
      ],
    };
    this.apartmentservice.createBookConsultation(formattedData).subscribe(
      (res) => {
        this.toastr.success(
          'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours'
        );
        this.loader = false;
        this.createForm();
      },
      (err) => {
        this.loader = false;
        this.toastr.error(err.error.message);
      }
    );
  }
  lazyload() {
    this.showgoogle = true;
  }
  filteredOptions: any;
  searchSocietyApi($event: any) {
    this.othersShow = true;
    this.isApifired = true;
    this.Typeselect = '';
    this.isSelected = false;
    let searchWord = $event.target.value;
    this.apartmentservice.getSocietyWebapi(searchWord).subscribe(
      (res: any) => {
        this.filteredOptions = res.result;
        console.log(this.filteredOptions);
      },
      (err) => {
        this.filteredOptions = [];
      }
    );
  }
  isSelected = false;
  Typeselect: any;
  isApifired = false;
  projectName2 = '';
  DeveloperName2: any;
  pincodePro2 = '';
  FullAdress2: any;
  citynamePro2: any;
  othersShow = false;
  selectOption(address: any) {
    this.filteredOptions = [];
    this.isSelected = true;
    if (address == 'other') {
      $('#OtherprojectName2').modal('show');
      this.projectName2 = this.BookingDetails.controls['property_name'].value;
      console.log(
        this.projectName2,
        this.BookingDetails.controls['property_name'].value,
        'test'
      );
      this.DeveloperName2 = '';
      this.FullAdress2 = '';
      this.citynamePro2 = '';
      this.pincodePro2 = '';
      this.AidApartmentID = '';
    } else {
      this.BookingDetails.controls['property_name'].setValue(
        address.apartment_name
      );
      this.projectName2 = this.BookingDetails.controls['property_name'].value;
      let location = this.projectName2 + ',' + address.area_name;
      this.BookingDetails.controls['city'].setValue(address.city);
      this.BookingDetails.controls['pincode'].setValue(address.pincode);
      this.BookingDetails.controls['apartment'].setValue(location);

      this.AidApartmentID = address.id;
    }

    console.log(this.BookingDetails.value);
  }
  closeaddApartmentrplanModal() {
    $('#OtherprojectName2').modal('hide');
    this.BookingDetails.controls['pincode'].setValue('');
    this.BookingDetails.controls['apartment'].setValue('');
    this.BookingDetails.controls['property_name'].setValue('');
  }
  areaname: any;
  Submitapart() {
    $('#OtherprojectName2').modal('hide');
    let area = this.areaname ? this.areaname : this.FullAdress2;
    let location = this.projectName2 + ',' + area;

    this.BookingDetails.controls['city'].setValue(this.citynamePro2);
    this.BookingDetails.controls['pincode'].setValue(this.pincodePro2);

    this.BookingDetails.controls['property_name'].setValue(this.projectName2);
    console.log(this.projectName2, this.BookingDetails.value);
    this.BookingDetails.controls['apartment'].setValue(location);
    console.log(location, 'location');
    this.CreateApartment();
  }
  CreateApartment() {
    let address = this.BookingDetails.controls['apartment'].value;
    const pincodeRegex = /\b\d{6}\b/;
    this.loader = true;

    // Use the match method to find the first match of the regex pattern in the string
    const pincodeMatch = address.match(pincodeRegex);

    // Check if a match is found
    if (pincodeMatch) {
      // Extracted PIN code
      var pincode = pincodeMatch[0];
    } else {
      pincode = '';
    }

    let obj = {
      rera_number: '',
      project_name: this.projectName2,
      full_address: this.FullAdress2,
      project_developer: this.DeveloperName2,
      pincode: this.BookingDetails.controls['pincode'].value,
      area_name: this.areaname,
      city: this.BookingDetails.controls['city'].value,
      state: '',
    };
    this.apartmentservice.CreateApar(obj).subscribe(
      (Res: any) => {
        this.loader = false;
        this.toastr.success('Apartment Created Successfully');
        this.AidApartmentID = Res.result[0].apartment_id;
      },
      (err) => {
        this.loader = false;
        this.toastr.error(JSON.parse(err['_body']).message);
      }
    );
  }
  preventSubmit(event: Event) {
    event.preventDefault();
  }
  hideOptions() {
    this.isApifired = false;
  }
  showOptions() {
    this.isApifired = true;
  }
}
