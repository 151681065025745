import { Component, OnInit, Sanitizer } from '@angular/core';
import { Slick } from 'ngx-slickjs';
declare var $:any;
@Component({
  selector: 'app-happycustomers',
  templateUrl: './happycustomers.component.html',
  styleUrls: ['./happycustomers.component.scss']
})
export class HappycustomersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  videoslist = [
    { image: "assets/shortform/images/video16.webp", url:'K32d62yDqxc', title: "3BHK, Made By The Bhargavas", location: "Whitefield, Bengaluru", videourl: "video1.mp4" },
    { image: "assets/shortform/images/video15.webp", url:'sdL3SzUNcGw', title: "3BHK, Made By The Bhatts", location: "Serilingampally, Hyderabad", videourl: "video2.mp4" },
    { image: "assets/shortform/images/video14.webp", url:'MKSqjdsLjpY', title: "2BHK, Made By The Mausles", location: "Andheri, Mumbai", videourl: "video3.mp4" },
    { image: "assets/shortform/images/video13.webp", url:'B9UyGhfBs', title: "2BHK, Made By The Khots", location: "Nerul, Navi Mumbai", videourl: "video4.mp4" },
    { image: "assets/shortform/images/video12.webp", url:'NmNTN357uKU', title: "2BHK, Made By The Rajes", location: "Balkum, Thane", videourl: "video5.mp4" },
    { image: "assets/shortform/images/video11.webp", url:'HZMaErn5sjM', title: "3BHK, Made By The Goyals", location: "Gurugram", videourl: "video6.mp4" }
  ];

  playthevideo=false;
  id:any='K32d62yDqxc'


  public ytEvent:any

  private player! :YT.Player

  playerVars = {
    cc_lang_pref: 'en',
  };

  videoWidth: any  = 100+"%"
  videoHeight:any  = 100+"%"


  savePlayer(player:any) {
    this.player = player;
  }
  onStateChange(event:any) {
    this.ytEvent = event.data;
  }

  pauseVideo() {
    this.player.pauseVideo();
  }

  mobilevideoPlayIn(id :any){
    this.playthevideo = true
    this.id =  id
    this.playVideo(id)
  }
  playVideo(id:any) {
    this.player.loadVideoById(id)
    this.player.playVideo();

  }

  isModalOpen = false; // Track modal state
  currentVideoUrl:any='https://www.youtube.com/embed/9L8szVqoJr8';

  openVideoModal(videoUrl: string) {
    $('#Youtubevideoplayer').modal('show');
    console.log(videoUrl)
    this.isModalOpen = true;
    this.id =  videoUrl
    this.player.loadVideoById(videoUrl)
    this.player.playVideo();
    
  }
  // Method to close the modal
  closeVideoModal() {
    this.isModalOpen = false;
    this.pauseVideo();
  }

  config: Slick.Config = {
    centerMode : true,
    centerPadding : '60px',
    infinite: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    // dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding : '40px',

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding : '40px',
          arrows : false,
          centerMode : true
        },
      },
    ],
  };




}

