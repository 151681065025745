<div class="container-fluid p-md-5 my-1 ">
    <div class="row">
        <div class="header col-12 d-flex flex-column justify-content-md-start justify-content-start px-md-3 px-5 pt-5 py-md-0 ">
            <h4 class="uppercase"><span  class="pb-2 custom-border">Aapke sapno </span> ka ghar, Ho Jayega!</h4>
            <p class="my-md-3 ">Hear from our happy customers</p>
            <!-- <button class="d-md-flex d-none  align-items-center rounded-pill peek uppercase d-md-block d-none">
                Take a peek &nbsp;
                <img src="assets/Longform/icons/Arrow 3.svg" alt="">
            </button> -->

        </div>
        <div class="col-12 videos mt-md-5 mt-2 p-0">
            <div class="desktop row d-md-block d-none px-md-3">
                <div class=" d-flex flex-wrap justify-content-between">
                    <div *ngFor="let option of videoslist" class="style-option">
                        <div style="position: relative;">
                        <img [src]="option.image" [alt]="option.title" class="option-image" >
                        <img class="playbtn cursorPointer" src="assets/Longform/icons/Play icon 5.svg" alt="" (click)="openVideoModal(option.url)">
                        </div>
                        <div class="option-label">{{ option.title }}</div>
                        <p class="option-location">{{option.location}}</p>
                    </div>
                  </div>
            </div>

            <div class="mobile d-md-none d-block">

                    <div class="w-100 cursorPointer videoposter"  *ngIf="!playthevideo">
                        <img class="poster" src="assets/Longform/images/video1.webp">
                        <img class="img-fluid playbtn cursorPointer" (click)="mobilevideoPlayIn(this.id)" style=" height: 20%;" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                    </div>             
             
                   <div class="w-100" *ngIf="playthevideo">
                    <youtube-player
                        [videoId]="id"
                        [width]="videoWidth"
                        (ready)="savePlayer($event)"
                        (change)="onStateChange($event)"
                        [playerVars]="playerVars"
                    ></youtube-player>
                   </div>

                   <div  class="videoThumbnails  py-4">
                      <div class="row w-100" slickContainer #slickController="slick" [slickConfig]="config">
                        <ng-container *ngFor="let option of videoslist">
                         <div slickItem class="col-3 px-1" >
                            <div class="w-100 cursorPointer videoposter"  >
                                <img class="poster" src={{option.image}}>
                                <img class="img-fluid playbtn" (click)="mobilevideoPlayIn(option.url)" style=" height: 20%;" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                            </div>                             
                            <div class="d-flex flex-column justify-content-center">
                                 <p class="option-label">{{option.title}}</p>
                                 <p class="option-location">{{option.location}}</p>
                                 <p class="option-descript">{{option.descript}}</p>
                            </div>
                         </div>
                         </ng-container>

                      </div> 
                      <!-- <div class="d-lg-none d-flex justify-content-center pb-3">
                        <button class="d-md-none d-block  align-items-center rounded-pill peek uppercase d-md-block d-none">
                          Take a peek &nbsp;
                          <img src="assets/Longform/icons/Arrow 3.svg" alt="">
                      </button>
                      </div> -->
                   </div>                
            </div>

        </div>
</div>




<!-- Modal box -->




  <div *ngIf="isModalOpen" class="modal fade" id="Youtubevideoplayer" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 600px;">
      <div class="modal-content">
        <div class="modal-header py-0 " style="border-bottom: none;">
          <button type="button" class="close iconbox" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="height: 325px;">
            <youtube-player
            [videoId]="id"
            [width]="videoWidth"
            [height]="videoHeight"
            (ready)="savePlayer($event)"
            (change)="onStateChange($event)"
            [playerVars]="playerVars"
        ></youtube-player>
        </div>

      </div>
    </div>
  </div>

