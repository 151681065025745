<div class=" OUR-STORES pt-3 ">
    <div class="d-flex flex-column justify-content-start main-heading  p-md-5 p-2 mb-3 mb-md-0">
       <p class="mb-1 "><span class="pb-1">OUR  STORES</span></p>
       <!-- <span>Our happy customers tell you why to choose Arrivae</span> -->
       <div>
       <button (click)="openInNewTab()"  type="button " class="mt-2 button  align-items-center d-md-flex d-none" style="width: fit-content;">VIEW MORE &nbsp;
         <span>
            <img src="../../../assets/images/Arrow 1.png"/>
         </span>
       </button>
      </div>
    </div> 
    <div class="body px-md-5 p-2 ">
        <div class="row">
          <div class="col-md-6 pb-3 pb-md-0">
            <a (click)="openModal('https://www.youtube.com/embed/vOSeT07sixE?autoplay=1&loop=1')" class="image cursorPointer">
              <div  class="image_carrier  align-items-end cursorPointer " >
                   <img class="w-100" src="../../../assets/images//stores/maxresdefault (1).jpg"/>
              </div>
              <div class="playIconStyles">
                <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
             </div>
           </a>
           <div class="d-flex flex-column align-items-center align-items-md-start pt-1">
           <span class="storeLocation ">VR Mall</span>
           <span>Bengaluru</span>
           </div>
          </div>
          <div class="col-md-6">
             <a (click)="openModal('https://www.youtube.com/embed/hbevvQ6FQVw?autoplay=1&loop=1')" class="image cursorPointer">
              <div  class="image_carrier  align-items-end cursorPointer " >
                   <img class="w-100" src="../../../assets/images//stores/storeimage2.png"/>
              </div>
              <div class="playIconStyles">
                <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
             </div>
           </a>
           <div class="d-flex flex-column align-items-center align-items-md-start pt-1">
            <span class="storeLocation ">Trillium Avenue</span>
            <span>Gurugram</span>
            </div>
          </div>

        </div>
    </div>
    <div class="d-md-none d-flex justify-content-center pb-3">
      <a routerLink='/visit-us' target="">
      <button  type="button " class="mt-2 button  align-items-center d-md-none d-flex">VIEW MORE &nbsp;
        <span>
           <img src="../../../assets/images/Arrow 1.png"/>
        </span>
      </button>
      </a>
   </div>
</div> 
<!-- video-modal.component.html -->
<!-- modal.component.html -->
<div id="myModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{modaltitle}}</h5>

        <button type="button" (click)="closeModal()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="w-100" *ngIf = "allVideos">
          <iframe allow="autoplay; muted" class="w-100" width="900" height="500" [src]="current_url" frameborder="0" allowfullscreen>
          </iframe>
          
       </div>
      </div>
      
    </div>
  </div>
</div>

  