import { Component, OnInit, ViewChild, ElementRef,Output,EventEmitter , Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { GooglePlaceDirective } from 'node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { ApartmentService } from 'src/app/apartment/apartment.service';
import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


declare var $: any;
declare var google :any





declare var $: any;
declare function searchApartment1(param: any): any;
declare function categoryClickapart(param: any): any;

declare function searchDesign(param: any): any;
declare function whatsappClick(): any;
declare function clickCategory(param: any): any;

@Component({
  selector: 'app-new-header-for-home',
  templateUrl: './new-header-for-home.component.html',
  styleUrls: ['./new-header-for-home.component.scss'],
 
})

export class NewHeaderForHomeComponent  implements OnInit {
  @ViewChild('searchCityModal', { static: true }) searchCityModal!: ElementRef;
  @Output() sendData: EventEmitter<any> = new EventEmitter();
  @Output() sendDataClass: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  @Input() verifyUser: any;
  @Input() l_lead_invitation: any;
  @Input() l_lead_id: any;
  @Input() parentSubject: Subject<any> | any;


  spaceDropdown: any;
  api_data: any;
  space_name: any;
  additionalDetails: any = FormGroup;
  BookingDetails:any = FormGroup
  filteredCity: any;
  city: any;
  life: any;
  term: any;
  window = window;
  route_url: any;
  navurl: any = '';
  searchStr: string = '';
  searchStr2: string = '';
  // loader: boolean = false;
  public mobileNumber: any;
  idForEachInclusion :any
  newDate:any;
  todayDate:any = new Date().toLocaleDateString('en-ca');
  currentUrl : any = ''

  successalert = false;
  successMessage: any;
  errorMessage: any;
  erroralert = false;
  showPopup: any;

  constructor(
    public sharedService: SharedService ,
    public router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public datepipe: DatePipe,
    private apartmentservice:ApartmentService,
    private modalService: NgbModal,


  ) {}

  ngOnInit(): void {
    // this.lightScrollbar();
    this.currentUrl = window.location.href.split('/')[3]
    this.newLandingPage()
    console.log(this.newLandingPage());
    
    this.handleWindowResize();
    this.animateNavLinks();
    this.getDropdownSpace();
    $('#Booking_details').hide();
    console.log(this.data);
    console.log(location.origin)
    console.log(window.location.href)
  
   
   
    this.getCities();
    this.loadScrpts()
    this.city = localStorage.getItem('selectedCity')
      ? localStorage.getItem('selectedCity')
      : '';
    this.next(this.city);
    this.life = this.city;

    this.getQuoteModalHideBs();
    this.route_url = this.router.url;
    this.createForm();
    const url2 = parseInt(window.location.href.split('/')[4])
    this.idForEachInclusion = url2
    this.loadScript()
    this.getNext12MonthNamesWithYear();
    if( this.parentSubject){
      this.parentSubject.subscribe((event:any) => {
        if(event){
          this.getQuoteContainer()
        }
  
  
      })
      
    }
    
  }
  BudgeValueData: any = [
    { id: 0, budgevalue: 'Less than 1.5 Lacs' },
    { id: 0, budgevalue: '1.5 - 3 Lacs' },
    { id: 0, budgevalue: '3 - 5 Lacs' },
    { id: 0, budgevalue: '5 - 8 Lacs' },
    { id: 0, budgevalue: '8 - 12 Lacs' },
    { id: 0, budgevalue: 'Above 12 Lacs' },
    { id: 0, budgevalue: 'Not Disclosed' },
  ];
createForm(){
   this.additionalDetails = this.fb.group({
     phone_number: [''],
     first_name: ['', Validators.required],
     last_name: ['', Validators.required],
     email: ['', [Validators.required, Validators.email]],
     apartment: [''],
     pincode: ['', Validators.required],
     cityname: [''],
     Property_Type:['', Validators.required],
     lead_invatation_id: [''],
     lead_id: '',
     connect_whatsapp: [true],
     aide_apartment_id:[''],
    marketing_page:true
     
   });
   this.BookingDetails = this.fb.group({
    budget: ['',Validators.required],
    possession_status: ['', Validators.required],
    possession_status_date: [''],
    store_visit_date:[''],
    store_visit_time:['', Validators.required],
    store_id:['',Validators.required],
    meridiem:['', Validators.required]
    
  });

}
  handleWindowResize() {
    $(window).resize(() => {
      if (this.navurl != '') {
        $('#menuContainer').addClass('lightHeader');
        $('#mobile-header').addClass('lightHeader');
      }
    });
  }

  // select city mumbai by default if not selected
  ngAfterViewInit() {
    if (!this.city) {
      setTimeout(() => {
        this.searchCityModal.nativeElement.click();
      }, 1000);
    }
    if (this.city === '') {
      this.city = 'mumbai';
    }
  }
 

  filteredCities:any =[]
  getCities() {
    this.filteredCities =[];
    this.sharedService.getCities().subscribe((data) => {
      this.filteredCity = data;
      this.filteredCity['data'].map((element: any) => {
        if (
          !(
            element.attributes.name == 'ahmedabad' ||
            element.attributes.name == 'chandigarh' ||
            element.attributes.name == 'delhi' ||
            element.attributes.name == 'hyderabad' ||
            element.attributes.name == 'jaipur' ||
            element.attributes.name == 'kochi' ||
            element.attributes.name == 'kolkata' ||
            element.attributes.name == 'lucknow' ||
            element.attributes.name == 'trivandrum' ||
            element.attributes.name == 'vijayawada' ||
            element.attributes.name == 'cochin' ||
            element.attributes.name == 'nashik'
          )
        ) {
          this.filteredCities.push(element);
        }

    });
    console.log(this.filteredCities);
  })
  }

  selectCity(locate: any) {
    this.city = locate;
    localStorage.setItem('selectedCity', locate);
    this.next(this.city);
    this.life = this.city;
    this.sharedService.data.next(this.city);
  }

  next(value: any) {
    this.sendData.emit({ selectedCity: value });
  }

  spacesExpand() {
    if ($('#spaces-li-mobile').hasClass('spaces-li-mobile-expanded')) {
      $('#spaces-li-mobile-dropdown').removeClass('dropdown-lists-spaces-show');
      $('#spaces-li-mobile').removeClass('spaces-li-mobile-expanded');
    } else {
      $('#spaces-li-mobile-dropdown').addClass('dropdown-lists-spaces-show');
      $('#spaces-li-mobile').addClass('spaces-li-mobile-expanded');
      $('#spaces-li-mobile-dropdown2').removeClass(
        'dropdown-lists-spaces-show2'
      );
      $('#spaces-li-mobile2').removeClass('spaces-li-mobile-expanded2');
    }
  }

  spacesExpand2() {
    if ($('#spaces-li-mobile2').hasClass('spaces-li-mobile-expanded2')) {
      $('#spaces-li-mobile-dropdown2').removeClass(
        'dropdown-lists-spaces-show2'
      );
      $('#spaces-li-mobile2').removeClass('spaces-li-mobile-expanded2');
    } else {
      $('#spaces-li-mobile-dropdown2').addClass('dropdown-lists-spaces-show2');
      $('#spaces-li-mobile2').addClass('spaces-li-mobile-expanded2');
    }
  }

  // get Quote Modal on hide.bs.modal method
  getQuoteModalHideBs() {
    $('#getQuoteModalHeader').on('hide.bs.modal', function () {
      if (
        $('#get-quote-container-header').hasClass(
          'get-quote-container-header-show'
        )
      ) {
        $('#get-quote-container-header').removeClass(
          'get-quote-container-header-show'
        );
        $('#get-quote-container-header').modal('hide');
      } else {
        $('#get-quote-container-header').addClass(
          'get-quote-container-header-show'
        );
      }
    });
  }

  // lightColor scrollbar on scroll
  // lightScrollbar() {
  //   $(window).scroll(function () {
  //     if (window.location.pathname == '/') {
  //       var scroll = $(window).scrollTop();
  //       if (scroll >= 30) {
  //         $('#menuContainer').addClass('lightHeader');
  //         $('#mobile-header').addClass('lightHeader');
  //       } else {
  //         $('#menuContainer').removeClass('lightHeader');
  //         $('#mobile-header').removeClass('lightHeader');
  //       }
  //     }
  //   });
  // }

  closeSearchCityModal() {
    $('#searchCityModal').modal('hide');
  }
  validatetetive(e:any){

  }

  animateNavLinks() {
    $('.nav-li')
      .delay(500)
      .each((index: number, elem: Element): void => {
        setTimeout(() => {
          $(elem).addClass('show');
        }, 100 * index);

        if (index == 5) {
          setTimeout(() => {
            $('.get-quote').addClass('show');
            $('.test_class').addClass('show');
          }, 100 * 6);
        }
      });
     

  }

  update_space: any;
  getDropdownSpace() {
    this.sharedService.getDropdownspace().subscribe((data) => {
      this.spaceDropdown = data;
      this.api_data = Object.values(this.spaceDropdown);
      this.space_name = this.api_data[0];
      var spaceList = this.space_name;
      var index1 = spaceList.indexOf('Bedroom');
      var index2 = spaceList.indexOf('Kids Room');
      var index3 = spaceList.indexOf('Kitchen');
      var index4 = spaceList.indexOf('Living');
      var space1 = this.space_name[index1];
      var space2 = this.space_name[index2];
      var space3 = this.space_name[index3];
      var space4 = this.space_name[index4];
     
    });
    this.update_space = ['Bedroom', 'Kids Room', 'Kitchen', 'Living'];
  }

  searchExpand() {
    if ($('#search-input-container').is(':visible')) {
      $('#search-input-container').hide();
      $('.nav-li').show();
    } else {
      $('#search-input-container').show();
      $('.nav-li').hide();
    }
  }

  menuExpand() {
    if ($('#menuContainer').hasClass('navbar-wrapper-show')) {
      $('#menuContainer').removeClass('navbar-wrapper-show');
      $('#nav-wrapper').css('height', 'unset');
      // spaces menu list collapse if expanded in show menu
      $('#spaces-li-mobile-dropdown').removeClass('dropdown-lists-spaces-show');
      $('#spaces-li-mobile').removeClass('spaces-li-mobile-expanded');
    } else {
      $('#menuContainer').addClass('navbar-wrapper-show');
      $('#nav-wrapper').css('height', '100vh');
    }
  }

  searchExpandMobile() {
    if ($('#mobile-menu-container').is(':visible')) {
      $('#mobile-menu-container').hide();
      $('#mobile-get-quote-div').hide();
      $('#search-input-container-mobile').css('display', 'flex');
      $('#search-input-container-mobile').css('transform', 'translate(0, 0)');
    } else {
      $('#mobile-menu-container').show();
      $('#mobile-get-quote-div').show();
      $('#search-input-container-mobile').hide();
    }
  }
  closeAndBack: boolean = false;
  setSecondNumber: any;
  getQuoteContainer() {
    if (
      $('#get-quote-container-header').hasClass(
        'get-quote-container-header-show'
      )
    ) {
      $('#getQuoteModalHeader').modal('hide');
      $('#get-quote-container-header').removeClass(
        'get-quote-container-header-show'
      );
    } else {
      $('#getQuoteModalHeader').modal('show');
      $('#get-quote-container-header').addClass(
        'get-quote-container-header-show'
      );
    }
    $('#Booking_details').hide();
    $('#additionalDetail').hide();
    $('#GetQuote').show();
    this.additionalDetails.reset();
    this.createForm();
    this.setSecondNumber = this.data;
    this.closeAndBack = true;
    this.mobileNumber =""
    var d2:any = this.datepipe.transform(this.todayDate, 'yyyy/MM/dd')
    var d1 = new Date(d2);
    var d3 = new Date('2022-10-12');
    this.dateRange(d1,d3);
      

    var dates = ["28-09-2022", "29-09-2022", "30-09-2022"];
    var newDates = this.dateRange(d1,d3);
    var newDates2:any =[]
   newDates = newDates.forEach((el:any) => {
      el = this.ReplaceString(el);
      newDates2.push(el);
   });
   console.log(newDates2)

     dates = newDates2 ;

    $("#calendar").datepicker({
      minDate : "+1",
      beforeShowDay: function(date:any){
        var string = $.datepicker.formatDate('dd-mm-yy', date);
        return [dates.indexOf(string) == -1];
    }
   
      
  });
  $('#calendar').datepicker('setDate', 'today');
    $('#ui-datepicker-div').css('clip', 'auto');  

  }

  getQuoteContainer2(){
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
    if (modal.componentInstance) {
      modal.componentInstance.page = "get_a_quote";
    }

  }
  ReplaceString(e:any){
    var word = e.split('/');
    var new_word:any =[];
    word.forEach((el:any)=> {
      new_word.push(el);
    });
    new_word = new_word.join("-")
   
    return new_word


  }
  DisplayBookingDetails:any = false;

  nextotpSection(quoteSection: any, otpSection: any) {
    $('#' + quoteSection).hide();
    $('#' + otpSection).show();
    $('#Booking_details').hide();
  }

  nextDetails(otpSection: any, additionalDetails: any) {
    $('#' + otpSection).hide();
    $('#' + additionalDetails).show();
    $('#Booking_details').hide();
  }
  nextSubmit(additionalDetails: any, querySubmit: any) {
    $('#' + querySubmit).show();
    $('#' + additionalDetails).hide();
    $('#Booking_details').hide();
  }
  nextFirst(quote: any, submit: any) {
    $('#' + quote).show();
    $('#' + submit).hide();
    $('#Booking_details').hide();

  }
  ProceedBooking() {
    $('#Booking_details').show();
    $('#additionalDetail').hide();
    $('#otpSection').hide();
    $('#GetQuote').hide();
    $('#querySubmit').hide();
    $('#get-quote-container-header').scrollTop(0);
    this.BookingDetails.reset();
    this.createForm();
    this.ActiveSpan = "";
  }


  timeLeft: any;
  interval: any;
  resendInterval: any;
  ResendenableDisable: boolean = false;

  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
        this.ResendenableDisable = true;
        this.displayTimer = false;
      }
    }, 1000);
  }
  
  resendTimer() {
    this.ResendenableDisable = false;
    this.timeLeft = 60;
    this.resendInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft - 1;
      } else {
        this.timeLeft = 0;
        this.ResendenableDisable = true;
        this.displayTimer = false;
      }
    }, 1000);
  }
  leadInvitationId: any;
  otpVerification() {
    this.loader = true
    this.mobileNumber = this.mobileNumber.toString();
    this.closeAndBack = true;
    var regexPhoneNo = /^\+?([0-9]{2})?[0-9]{10}$/;
    console.log( this.mobileNumber,'1')
    if (this.mobileNumber && this.mobileNumber.match(regexPhoneNo)) {
      this.setMobileNumber = this.mobileNumber;
      this.sharedService.optGeneration(this.mobileNumber).subscribe(
        (res: any) => {
          if (res) {
            this.startTimer();
            this.loader = false
            this.displayTimer = true;
            this.leadInvitationId = res.data.lead_invitation_id;
            this.toastr.success(res.data.message);
            this.nextotpSection('GetQuote', 'otpSection');
            console.log( this.setMobileNumber,'2')
          }
        },
        (err) => {
          this.loader = false
          this.toastr.error(err.error.data.message);
        }
      );
    } else {
      this.loader = false
      this.toastr.error('Please Enter valid mobile number.');
    }
  }
  goBackOtp(otsec: any, getQu: any) {
    clearInterval(this.interval);
    clearInterval(this.resendInterval);
    this.displayErrorMessage = false;
    $('#' + otsec).hide();
    $('#' + getQu).show();
  }
  resendOtp() {
    this.loader = true
    this.ResendenableDisable = false;
    this.displayErrorMessage = false;
    var regexPhoneNo = /^\+?([0-9]{2})?[0-9]{10}$/;
    if (this.mobileNumber && this.mobileNumber.match(regexPhoneNo)) {
      this.setMobileNumber = this.mobileNumber;
      this.sharedService.optGeneration(this.mobileNumber).subscribe(
        (res: any) => {
          if (res) {
            this.loader = false
            this.resendTimer();
            this.displayTimer = true;
            this.leadInvitationId = res.data.lead_invitation_id;
            this.toastr.success(res.data.message);
            this.nextotpSection('GetQuote', 'otpSection');
          }
        },
        (err) => {
          this.loader = false
          this.toastr.error(err.error.data.message);
        }
      );
    } else {
      this.loader = false
      this.toastr.error('Please Enter valid mobile number.');
    }
  }
  setMobileNumber: any;
  enteredOptNumber: any;
  displayErrorMessage: boolean = false;
  displayTimer: boolean = false;
  verifyOtp() {
    this.loader = true
    this.setMobileNumber = this.mobileNumber
    console.log( this.setMobileNumber,"3")
    this.sharedService
      .optVerification(true, this.enteredOptNumber, this.leadInvitationId)
      .subscribe(
        (res: any) => {
          if (res) {
            this.enteredOptNumber = '';
            this.nextDetails('otpSection', 'additionalDetail');
            this.loader = false
            this.setMobileNumber = this.mobileNumber;
            this.setMobileNumber = parseInt( this.setMobileNumber)
          }
        },
        (err: any) => {
          this.loader = false
          this.displayErrorMessage = true;
          this.displayTimer = false;
          this.enteredOptNumber = '';
          this.ResendenableDisable = true;
        }
      );
  }
pincode:any;
  formattedaddress = ' ';
  handleAddressChange(address: any) {
    this.formattedaddress = address.name + "," + address.formatted_address;
    var str = this.formattedaddress;
    var arr = [];
    arr = str.split(",");
    var unique = [];
    for (i = 0; i < arr.length; i++) {
      if ((i == arr.indexOf(arr[i])) || (arr.indexOf(arr[i]) == arr.lastIndexOf(arr[i])))
        unique.push(arr[i]);
    }
    this.formattedaddress = unique.join(",");

    console.log(this.formattedaddress);
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == 'postal_code') {
        ;
        this.additionalDetails.controls['pincode'].setValue(address.address_components[i].long_name) 
                  
        } else{
          this.additionalDetails.controls['pincode'].setValue("");
                  }

        this.additionalDetails.controls['apartment'].setValue(
             this.formattedaddress)

            if (address.address_components[i].types[j] == 'locality') {
              this.additionalDetails.controls['cityname'].setValue(
                address.address_components[i].long_name
              );
console.log(this.additionalDetails.controls['cityname'].value);
              console.log(address.address_components[i].long_name);

            } 

      }
    }

  } 
  LeadIdFromres:any;
  DetailsSubmit() {
    this.loader = true
    if (this.additionalDetails.valid) {
      this.additionalDetails.controls['phone_number'].setValue(
        this.setMobileNumber.toString()
      );
      this.additionalDetails.controls['lead_invatation_id'].setValue(
        this.leadInvitationId
      );

      this.sharedService
        .additionalDetails(this.additionalDetails.value)
        .subscribe((res: any) => {
          console.log(res);
          this.loader = false
          
          this.additionalDetails.reset();
          this.createForm();
          if(res.message?.lead_id){
            
            this.LeadIdFromres = res.message?.lead_id
            this.toastr.success(res.message.message)
            this.GetcenersList()
          } else{
            this.nextSubmit('additionalDetail', 'querySubmit');
            this.toastr.success(res.message.message)

          }

         
        },err=>{
          this.loader = false
        });
    }
  }
  DetailsSecond() {
    if (this.additionalDetails.valid) {
      this.additionalDetails.controls['phone_number'].setValue(
       this.setMobileNumber.toString()
      );
      this.additionalDetails.controls['lead_invatation_id'].setValue(
        this.l_lead_invitation
      );
      this.additionalDetails.controls['lead_id'].setValue(this.l_lead_id);
      this.sharedService
        .additionalDetails(this.additionalDetails.value)
        .subscribe((res: any) => {
          console.log(res);
          this.nextSubmit('secondDetail', 'querySubmit');
          this.additionalDetails.reset();
          this.createForm();
        });
    }
  }
  closeReset() {
    $('#getQuoteModalHeader').modal('hide');
    this.nextFirst('GetQuote', 'querySubmit');
    this.mobileNumber = '';
    this.DisplayBookingDetails = false;
  }
  closesecondReset() {
    this.verifyUser = !this.verifyUser;
    $('#getQuoteModalHeader').modal('hide');
    this.nextFirst('GetQuote', 'querySubmit');
    this.mobileNumber = '';
  }

  closemodal() {
    $('#exampleModal').modal('hide');
  }

  closedesignmodal() {
    $('#searchModal').modal('hide');
  }

  search_string: any = '';
  searchApartmentFilter(event: any) {
    this.search_string = event.target.value;
    searchApartment1(this.search_string);
    this.apartmentList(this.city);
  }

  designsearch_string: any = '';
  searchDesignFilter(event: any) {
    this.designsearch_string = event.target.value;
    searchDesign(event.target.value);
    this.designList();
  }

  apartmentList(city: any) {
    $('#exampleModal').modal('hide');
    this.router.navigate(['/apartment'], {
      queryParams: { city: '', search: this.search_string },
    });
  }

  designList() {
    $('#searchModal').modal('hide');
    this.router.navigate(['/spaces'], {
      queryParams: { search: this.designsearch_string },
    });
  }

  modelChangetwo(str: string): void {
    this.searchStr2 = str;
  }

  searchDesignFilterTwo() {
    this.designsearch_string = this.searchStr2;
    searchDesign(this.search_string);
    this.designList();
  }

  filtervalue: any;
  searchApartmentFilterTwo() {
    this.search_string = this.searchStr;
    searchApartment1(this.search_string);
    this.apartmentList(this.city);
  }

  modelChange(str: string): void {
    this.searchStr = str;
    // Add code for searching here
  }

  whatsappClick() {
    whatsappClick();
  }

  categoryClick(data: any) {
    clickCategory(data);
  };
  
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  validateApartment(e:any){
    e = e.target.value;
    if(e =='apartment' || e =='villa'){
      this.additionalDetails.controls['apartment'].setValidators(Validators.required);
      this.additionalDetails.controls['apartment'].updateValueAndValidity;
    } else{
      this.additionalDetails.controls['apartment'].clearValidators();
      this.additionalDetails.controls['apartment'].setErrors(null);
      this.additionalDetails.controls['apartment'].updateValueAndValidity;
      console.log("jjj");
    }
    console.log(e);
    console.log(this.additionalDetails.controls)
    
    


  }
  
  newDateErr: any = "2";
  possession_date:any;
  project_start_date:any
  getNext12MonthNamesWithYear() {
    var now = new Date();
    var month = now.getMonth();
    var year = now.getFullYear();
    var names = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.possession_date = [];
    for (var i = 0; i < 13; ++i) {
      this.possession_date.push(names[month] + ' ' + year);
      if (++month === 12) {
        month = 0;
        ++year;
      }
    }
    this.project_start_date = this.possession_date;
    return this.possession_date;
  }
  dateOfSelect:any;
  todayDate2:any;
  reasonNewDateHandler(e:any){
    console.log(e.target.value);
    this.dateOfSelect = e.target.value;
    this.dateOfSelect = this.datepipe.transform(this.dateOfSelect, 'MM/dd/yyyy');
    this.todayDate2 = this.datepipe.transform(this.todayDate, 'MM/dd/yyyy');
    console.log( this.dateOfSelect);
    console.log( this.todayDate2);
    this.dateDiffInDays( this.dateOfSelect,this.todayDate2);
   
    
     

  }
   dateDiffInDays(a:any, b:any) {
     const date1: any = new Date(b);
     const date2: any = new Date(a);
     const diffTime = Math.abs(date2 - date1);
     console.log(diffTime);
     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
     console.log(diffDays);
     if(diffDays < 17 && diffDays > 2  ){
       this.newDateErr = true;
       this.newDate = "";
       $("input[type=date]").val("")
       this.BookingDetails.controls['store_visit_date'].setValue("")
       alert("You can Not Pick days between 14days from Today Except tomorrow and Day after tomorrow");
     } else{

      this.newDateErr = false;
      this.BookingDetails.controls['store_visit_date'].setValue(this.datepipe.transform(this.dateOfSelect, 'dd/MM/yyyy'))
      

     }

  }
  ActiveSpan:any ="";
  AddSpanTimeSlot(e1:any,e2:any){
    this.ActiveSpan = e1;
    this.BookingDetails.controls['store_visit_time'].setValue(e1);
    this.BookingDetails.controls['meridiem'].setValue(e2);
    console.log(this.BookingDetails.value)
    
  }
  EcCentersList:any =[]

  GetcenersList(){
    this.sharedService.GetECDetails(this.LeadIdFromres).subscribe(res=>{
      let res2:any = res;
      this.EcCentersList  = res2.experience_centers;
      if(this.EcCentersList.length >0 ){
        this.ProceedBooking();
      } else{
        this.toastr.error("No Experience Centers are Available");
        this.nextSubmit('additionalDetail', 'querySubmit');

      }

    },err=>{
      this.nextSubmit('additionalDetail', 'querySubmit');
    })
  }
  
  loader:boolean = false;
  BookingDetailsSubmit(){
    this.loader = true
    console.log(this.BookingDetails.value)
    var date =  $("#calendar").datepicker("getDate");
    console.log(date,"jjjj");
    date = this.datepipe.transform(date, 'dd/MM/yyyy');
    console.log(date,"jjjj");
    
    this.BookingDetails.controls['store_visit_date'].setValue(date);

    console.log(this.BookingDetails.value)

  this.sharedService.BookingDetails(this.LeadIdFromres,this.BookingDetails.value).subscribe((res:any)=>{
    $('#querySubmit').show();
    $('#Booking_details').hide();
    this.newDate = "";
    this.ActiveSpan ="";
    this.loader = false;
    this.toastr.success(res.message)
    
  },err=>{
    this.loader = false
  })

  }
  dateRange(startDate:any, endDate:any) {
    const date = new Date(startDate.getTime());

  const dates = [];
  var date4 = new Date();
date4.setDate(date.getDate() + 13);

console.log(date4);
endDate = date4


  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
    
  }
  var dates2:any =[]
    dates.forEach(el=>{
    dates2.push(this.datepipe.transform(el, 'dd/MM/yyyy'))
    })
  
  dates2.splice(0, 3)
 
  return dates2;
  }
  formatAMPM(date:any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  loadScrpts(){

    let nodelove = document.createElement('script');
  nodelove.src =  "https://code.jquery.com/ui/1.13.2/jquery-ui.js";
  nodelove.async = true;

  document.getElementsByTagName('head')[0].appendChild(nodelove);
  let google = document.createElement('script');
  google.src =  "https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=AIzaSyADhbP6QmjHlcay55cH4SklR2HmeJ_VC98";
  

  

  }
  triggerevent(e:any){
    if(this.roletoSet() < 650){

      this.menuExpand()

    }
    if( this.HandleHome() !='home'){

      if(e == 'how-it-works23' ){
        this.router.navigate(['/']);



      } 
      else{

        if(e == 'Made-By-You'){



          this.router.navigate(['/'] , { queryParams: { scroll : e } });


        } else{
          window.open('https://blog.arrivae.com/','_blank')
        }
        

       
      }

    } else{

   

      this.sendDataClass.emit(e);

    }
   
  
   
  }
  HandleHome(){
    if(location.origin+'/' != window.location.href && !window.location.href.includes('arrivae-living') && !window.location.href.includes('Made-By-You')) {
      return 'nothome'


      


    } else{


      return 'home'

    }
  }
  // roletoSet() {
  //   var width = Math.max(window.screen.width, window.innerWidth)

  //   if(width < 650){
     

  //     if ($('#menuContainer').hasClass('navbar-wrapper-show')) {
  //       $('#nav-wrapper').css('height', '100vh');
  //       $('#mobile-hide-menu').css('display','none');

       
  
  //     } else{
      
  //       $('#mobile-hide-menu').css('display','block');
  
  //     }

  //   } else{

  //     $('#mobile-hide-menu').css('display','block');
  //     $('#nav-wrapper').css('height', 'unset');
     

  //   }
 
   
  //   return width

  // }

  roletoSet() {
    var width = Math.max(window.screen.width, window.innerWidth)
    if(width > 650){
      $('#nav-wrapper').css('height', 'unset');
    }
    return width
  }
  SpaceFilter(space:any){
    

    const host: string =  location.origin;
    const url: string = host  + String(this.router.createUrlTree(['/spaces/listing'], { queryParams: {
      space_name:space
    } }));
    window.open(url, '_self');

  }




  newLandingPage(){
    if(this.currentUrl == 'new-landing'){
      return true
    }else{
      return false
    }
  }

  modalSearch1(){
    $('#exampleModal').modal('show');
   

  }
  modalSearch2(){
    $('#searchModal').modal('show');

  }

  AidApartmentID:any
  filteredOptions:any
  searchSocietyApi($event:any) {
    this.othersShow = true;
    this.isApifired = true;
    this.Typeselect = ''
    this.isSelected = false
    let searchWord = $event.target.value;
    this.apartmentservice.getSocietyWebapi(searchWord).subscribe(
     ( res:any )=> {
        this.filteredOptions = res.result
        console.log(this.filteredOptions)


      },
      err => {
        this.filteredOptions = []

      }
    );
  }
  isSelected = false;
  Typeselect:any;
  isApifired = false
  projectName ='';
  DeveloperName:any;
  pincodePro ='';
  FullAdress:any;
  citynamePro:any;
  othersShow = false;
  selectOption(address:any){

    this.filteredOptions = []
    this.isSelected = true
    if(address == 'other'){

      $('#OtherProjectname').modal('show');
      this.projectName =   this.additionalDetails.controls['apartment'].value;

      this.DeveloperName =''
      this.FullAdress ='';
      this.citynamePro = '';
      this.pincodePro = ''
      this.AidApartmentID = ''

    } else{
      this.additionalDetails.controls['cityname'].setValue(address.city)
      this.additionalDetails.controls['pincode'].setValue(address.pincode)
      // this.additionalDetails.controls['apartment'].setValue(address.area_name)
      this.additionalDetails.controls['apartment'].setValue(address.apartment_name)
      this.projectName =   this.additionalDetails.controls['apartment'].value;
      let location = this.projectName +','+ address.area_name
      this.additionalDetails.controls['aide_apartment_id'].setValue(address.id)
      this.additionalDetails.controls['apartment'].setValue(location)
    }


  }
  closeaddApartmentrplanModal(){
    $('#OtherProjectname').modal('hide');
    this.additionalDetails.controls['pincode'].setValue('')
    this.additionalDetails.controls['apartment'].setValue('')
  

  }
  areaname:any
  Submitapart(){
    $('#OtherProjectname').modal('hide');
    let area = this.areaname?this.areaname:this.FullAdress
    let location = this.projectName +','+ area
    this.additionalDetails.controls['cityname'].setValue(this.citynamePro)
    this.additionalDetails.controls['pincode'].setValue(this.pincodePro)
    this.additionalDetails.controls['apartment'].setValue(location)
    // this.additionalDetails.controls['apartment'].setValue()
    console.log(this.projectName)
    this.CreateApartment()
  }
  CreateApartment(){
    let address = this.additionalDetails.controls['apartment'].value;
    const pincodeRegex = /\b\d{6}\b/;
    this.loader = true

// Use the match method to find the first match of the regex pattern in the string
const pincodeMatch = address.match(pincodeRegex);

// Check if a match is found
if (pincodeMatch) {
  // Extracted PIN code
  var pincode = pincodeMatch[0];

} else {
  pincode =''
}

    let obj ={
      "rera_number": "",
      "project_name": this.projectName,
      "full_address": this.FullAdress,
      "project_developer": this.DeveloperName,
      "pincode":  this.additionalDetails.controls['pincode'].value,
      "area_name":this.areaname,
      "city":  this.additionalDetails.controls['cityname'].value,
      "state": "",
  }
     this.apartmentservice.CreateApar(obj).subscribe((Res:any)=>{
      this.loader = false
      this.toastr.success('Apartment Created Successfully')
      this.additionalDetails.controls['aide_apartment_id'].setValue(Res.result[0].apartment_id)	;
     },err=>{
      this.loader = false
    this.toastr.error(JSON.parse(err['_body']).message)
     })


  }

  handleAddressChange2(address: any) {

    this.FullAdress =   address.name
    this.formattedaddress = address.name;
  
    this.formattedaddress = address.name + "," + address.formatted_address;
    var str = this.formattedaddress;
    var arr = [];
    arr = str.split(",");
    var unique = [];
    for (i = 0; i < arr.length; i++) {
      if ((i == arr.indexOf(arr[i])) || (arr.indexOf(arr[i]) == arr.lastIndexOf(arr[i])))
        unique.push(arr[i]);
    }
    this.formattedaddress = unique.join(",");
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == 'postal_code') {
          console.log(address.address_components[i].long_name);
          this.pincodePro =  address.address_components[i].long_name
        } else{
          this.pincodePro = ''
        }
      
        this.FullAdress = this.formattedaddress;
        var areaName = address.address_components.filter((component:any) => component.types.includes('sublocality'));
        const concatenatedNames = areaName.map((component:any) => component.long_name).join(', ');
        areaName = concatenatedNames
        this.areaname=  areaName ?areaName : this.formattedaddress;
              if (address.address_components[i].types[j] == 'locality') {
                this.citynamePro =  address.address_components[i].long_name
              } 
      }
    }
  } 

}

