import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expert-section',
  templateUrl: './expert-section.component.html',
  styleUrls: ['./expert-section.component.scss']
})
export class ExpertSectionComponent implements OnInit {

  constructor() { }

  variations=[
    {image:'assets/shortform/icons/staircase.svg',descript:'Cookie-cutter interior solutions'},
    {image:'assets/shortform/icons/provision.svg',descript:'Hassle of dealing with 20 different vendors'},
    {image:'assets/shortform/icons/metal.svg',descript:'Mediocre material options'},
    {image:'assets/shortform/icons/mess.svg',descript:'Cluttered spaces'},
  ]

  variations2=[
    {image:'assets/shortform/icons/furbishing 1.svg',descript:'Personalised interior solutions'},
    {image:'assets/shortform/icons/vehicle.svg',descript:'End-to-end solutions under one roof'},
    {image:'assets/shortform/icons/advance-technology 2.svg',descript:'30+ curations of best-in-class materials'},
    {image:'assets/shortform/icons/layout 2.svg',descript:'Space maximisation with 20% more usable space'},
  ]

  ngOnInit(): void {
  }

}
