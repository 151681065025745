import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApartmentService } from '../apartment.service';
declare var $:any;
declare function designpersondetail(
  param1: any,
  param2: any,
  param3: any,
  param4: any,
  param5: any
): any;
declare function propertyDetail(
  param1: any,
  param2: any,
  param3: any
): any;
declare function possesionDetail(
  param1: any,
  param2: any,
  param3: any,
  param4: any
): any;
declare function loanDetail(param1: any, param2: any, param3: any): any;
@Component({
  selector: 'app-apartment-get-this-design-form',
  templateUrl: './apartment-get-this-design-form.component.html',
  styleUrls: ['./apartment-get-this-design-form.component.scss']
})
export class ApartmentGetThisDesignFormComponent implements OnInit {

  @Input() apartment_name:any;
  @Input() cityname:any;
  @Input() pincode: any;
  @Input() bhk: any;
  @Input() id: any;
  @Input() floorplan: any;
  @Input() num:any;
  @Input() data: any;

  @Input() items: any;

  


  constructor( private modal: NgbActiveModal,
    private fb: FormBuilder, private apartmentservice:ApartmentService,
    private toastr:ToastrService) { }

  getThisDesignForm1:any= FormGroup;
  getThisDesignForm2:any= FormGroup;
  getThisDesignForm3:any= FormGroup;
  getThisDesignForm4:any= FormGroup;

  disabledCity: boolean = true;
  selectedCityname:any;
  pincodedata:any;
  

  isChecked: boolean = false;
  leadData:any;
  updateLeadData:any;
  selectedScopeWork:any;
  hidesearch : boolean = false;
  mymodelpincode:any =  '';
  mymodelcheckbox:any = '';
  hideFinancialAssistance: boolean = false;
  selectedHomeloadValue:any
  possession_date:any
  project_start_date:any;

  autoSelectedApartment:any;
  autoSelectedCityName:any
  autoSelectedPincode: any;
  autoSelectedBHK: any;
  autoSeelectedId: any;
  autoSelectedplan: any;
  loader:boolean = false;
  googleApihiteed:boolean=false


  ngOnInit(): void {
    this.autoSelectedApartment = this.apartment_name;
    this.autoSelectedCityName = this.cityname.charAt(0).toUpperCase() + this.cityname.slice(1);
    this.autoSelectedPincode = this.pincode;
    // this.autoSelectedBHK = this.bhk;
    this.autoSeelectedId = this.id;
    if (this.bhk === '1bhk') {
    this.autoSelectedBHK = '1BHK';
    }
    else if (this.bhk === '1.5bhk') {
      this.autoSelectedBHK = '1.5BHK';
    }
    else if (this.bhk === '2bhk') {
      this.autoSelectedBHK = '2BHK';
    }
    else if (this.bhk === '2.5bhk') {
      this.autoSelectedBHK = '2.5BHK';
    }
    else if (this.bhk === '3bhk') {
      this.autoSelectedBHK = '3BHK';
    }
    else if (this.bhk === '3.5bhk') {
      this.autoSelectedBHK = '3.5BHK';
    }
    else if (this.bhk === '4bhk') {
      this.autoSelectedBHK = '4BHK';
    }
    else if (this.bhk === '4.5bhk') {
      this.autoSelectedBHK = '4.5BHK';
    }
    else if (this.bhk === '5bhk') {
      this.autoSelectedBHK = '5BHK';
    }
    
     this.autoSelectedplan = this.floorplan;
    this.createFirstForm();
    this.createSecondForm();
    this.createThirdForm();
    this.createForthForm();
    this.getNext12MonthNamesWithYear();
    this.loadScript()
  }
   
  onBlurName(event: any) {
    if (event.target.value !== "") {
      this.errormsgname = false;
    }
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  onBlur(event:any) {
    if (event.target.value.length === 10) {
      this.errormsgmobile = false;
    }
  }
  

  createFirstForm() {
    this.getThisDesignForm1 = this.fb.group({
      fullName: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      emailAddress: [''],
      propertyName: [''],
      Locality:[''],
      apartment:[''],
      city: [this.autoSelectedCityName],
      pincode: [this.autoSelectedPincode]
    });
  }

  get f() { return this.getThisDesignForm1.controls; }

  createSecondForm() {
    this.getThisDesignForm2 = this.fb.group({
      scopeOfWork:[''],
      accomandationType: [this.autoSelectedBHK],
      homeValue: [''],
      doYouHaveFloorPlan: [this.autoSelectedplan],
      uploadfloorPlanFile:[''],
    });
  }

  createThirdForm() {
    this.getThisDesignForm3 = this.fb.group({
      lookingFor: [''],
      possessionProperty_date: [''],
      startProject_date: [''],
      yourBudge:[''],
    });
  }

  createForthForm() {
    this.getThisDesignForm4 = this.fb.group({
      existingHomeLone: [''],
      financialassistance: [''],
      purposeProperty: [''],
      tellMeAboutYourself: ['']
    });
  }

  onChangehomeloanvalue(event:any) {
    this.selectedHomeloadValue = event.target.value;
    if(this.selectedHomeloadValue === 'Yes') {
      this.hideFinancialAssistance = true;
    } else {
      this.hideFinancialAssistance = false
    }
  }

  scopeWork(event:any) {
    this.selectedScopeWork = event.target.value
    if(!this.selectedScopeWork) {
      this.getThisDesignForm1.get('city').disable();
    } else {
      this.getThisDesignForm1.get('city').enable();
    }
  }

  valuechange(newValue:any) {
    this.mymodelpincode = newValue;
    if(!this.mymodelpincode) {
      this.hidesearch = false
    } else {
      this.hidesearch = true
    }
  }

  mymodelcheckboxYes:boolean = true
  mymodelcheckboxNo:boolean = false
  radiobuttonUploadCondition: boolean = false
  valuechangeUploadYes(event:any) {
    this.mymodelcheckboxYes = event;
    this.radiobuttonUploadCondition = true;
  }

  valuechangeUploadNo(event:any) {
    this.mymodelcheckboxNo = event;
    this.radiobuttonUploadCondition = false;
  }

  valuechangeUploadCheckbox(event:any) {
    this.mymodelcheckbox = event;
  }

  cancelOne() {
    this.modal.close();
  }
  resultaddress:any
  checkprecence(apart_name: any) {
    return this.apartmentservice.checkprecence(apart_name).subscribe(
      (res: any) => {
        console.log("hiiii");
        console.log(res.result.length);
        if(res.result.length > 0)
        {
        this.resultaddress=res.result[0]
        this.getThisDesignForm1.controls['apartment'].setValue(this.resultaddress.apartment_name+','+this.resultaddress.area_name )
        this.getThisDesignForm1.controls['pincode'].setValue(this.resultaddress.pincode)
        console.log(this.resultaddress)
        this.onSubmit(true)
        }
        else{
          this.onSubmit(false)
        }

      },
      (error: any) => {
        console.error(error);
        // Handle error if needed
        this.onSubmit(false)

      }
    );
  }
  onSubmit(flag:any) {
    console.log(this.getThisDesignForm1.value.propertyName)
    let postObj;
    // this.loader = true;
    if(flag){
     postObj =
     {
        lead: {
           "name" : this.getThisDesignForm1.value.fullName,
           "contact" : this.getThisDesignForm1.value.mobileNumber,
           "lead_source": 'website-apartment',
           "lead_campaign": '',
         'lead_utm_medium': '',
           'lead_utm_term': '',
           'lead_utm_content': '',
           'email': this.getThisDesignForm1.value.emailAddress,
           'city': this.getThisDesignForm1.value.city,
           'pincode': this.getThisDesignForm1.value.pincode,
           'location': this.resultaddress.apartment_name+','+this.resultaddress.area_name ,
           'property_name': this.resultaddress.apartment_name,
           'additional_comments': this.getThisDesignForm4.value.tellMeAboutYourself || "",
           "aide_apartment_id": this.resultaddress.id,
           "marketing_page": true
        }
    
      }
    }
    else{
      postObj =
     {
        lead: {
           "name" : this.getThisDesignForm1.value.fullName,
           "contact" : this.getThisDesignForm1.value.mobileNumber,
           "lead_source": 'website-apartment',
           "lead_campaign": '',
         'lead_utm_medium': '',
        //  'id': this.autoSeelectedId,
           'lead_utm_term': '',
           'lead_utm_content': '',
           'email': this.getThisDesignForm1.value.emailAddress,
           'city': '',
           'pincode': '',
           'location': '',
           'property_name': '',
           'additional_comments': this.getThisDesignForm4.value.tellMeAboutYourself || "",
           "aide_apartment_id": '',
           "marketing_page": true
        }
    
      }

    }

    // if(this.getThisDesignForm1.valid) {
    //   this.apartmentservice.createBookConsultation(postObj).subscribe(res => {
    //     this.leadData = res;
    //     this.loader= false
    //     this.toastr.success("Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours");
    //     loanDetail(
    //       this.getThisDesignForm4.value.existingHomeLone,
    //       this.getThisDesignForm4.value.purposeProperty,
    //       this.getThisDesignForm4.value.tellMeAboutYourself
    //     );
    //     if (this.leadData.lead.duplicate === false) {
    //       if (this.getThisDesignForm1.value.emailAddress || this.getThisDesignForm2.value.scopeOfWork || this.getThisDesignForm1.value.city || this.getThisDesignForm1.value.pincode || this.getThisDesignForm3.value.possessionProperty_date || this.getThisDesignForm3.value.startProject_date || this.getThisDesignForm1.value.propertyName ||
    //         this.getThisDesignForm3.value.yourBudge || this.getThisDesignForm2.value.accomandationType || this.getThisDesignForm2.value.homeValue || this.getThisDesignForm4.value.purposeProperty
    //         || this.getThisDesignForm4.value.existingHomeLone || this.getThisDesignForm4.value.tellMeAboutYourself || this.fileupload || this.items
    //       ){
    //         return 
    //       }
    //       else{
    //         this.toastr.success("Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours");
    //       }
    //     }
    //     else{
    //       this.loader = false;
    //       this.toastr.error('User Already Exist');
    //     }

 
    //   }, error =>  {
    //     this.toastr.error(error.error.message);
    //      this.modal.close();
    //      this.loader = false
    //   });
    // } else {
    // }
  
  }


  fileupload:any;
  onFileSelect(event:any){
    if (event.target.files.length > 0) {
      this.fileupload = event.target.files[0];
    }
    
  }

  all_items: any = [];
  updateleadstatusdata() {
    this.loader = true
    this.all_items = [];

    if (this.num === 1) {
      for (let i = 0; i < this.data.space_items.data.length; i++){
        this.all_items.push({
          'name': this.data.space_items.data[i].attributes.item_name,
          'price': 0,
          'quantity': 1
        })
      }
    }
    let formdt = new FormData();

    // formdt.append(
    //   "lead_campaign",'null'
    // )
    // formdt.append(
    //   "apartment_id", this.autoSeelectedId
    // )
    
    // formdt.append(
    //   "lead_source", 'website-apartment'
    // )
    // formdt.append(
    //   "email", this.getThisDesignForm1.value.emailAddress
    // )

    // formdt.append(
    //   "city", this.getThisDesignForm1.value.city
    // )



    // formdt.append(
    //   "scope_of_work", this.getThisDesignForm2.value.scopeOfWork
    // )
    // formdt.append(
    //   "home_value", this.getThisDesignForm2.value.homeValue
    // )
    // formdt.append(
    //   "accomodation_type", this.getThisDesignForm2.value.accomandationType
    // )



    // formdt.append(
    //   "have_floorplan", ((this.radiobuttonUploadCondition === true) ? "Yes" : "No")
    // )
    // if (this.fileupload) {
    //   formdt.append(
    //     'lead_floorplan[]',  this.fileupload, this.fileupload.name
    //   ) 
    // }
    // else {
    //   formdt.append(
    //     'lead_floorplan[]',  '',
    //   )
    // }

    // formdt.append(
    //   "home_type",this.getThisDesignForm3.value.lookingFor
    // )
    // formdt.append(
    //   "possession_status_date", this.getThisDesignForm3.value.possessionProperty_date
    // )
    // formdt.append(
    //   "project_start_date", this.getThisDesignForm3.value.startProject_date
    // )
    // formdt.append(
    //   "customer_budget",this.getThisDesignForm3.value.yourBudge
    // )


    // formdt.append(
    //   "purpose_of_property",this.getThisDesignForm4.value.purposeProperty
    // )
    // formdt.append(
    //   "have_home_loan", this.getThisDesignForm4.value.existingHomeLone
    // )
    // formdt.append(
    //   "financial_solution_required",(this.getThisDesignForm4.value.existingHomeLone === "Yes" ? this.getThisDesignForm4.value.financialassistance == 'Yes' ? true : false)
    // )
    // formdt.append(
    //   "additional_comments", this.getThisDesignForm4.value.tellMeAboutYourself
    // )



    // formdt.append(
    //   "location", this.getThisDesignForm1.value.apartment
    // )
    // formdt.append(
    //   "pincode", this.getThisDesignForm1.value.pincode
    // )
    // formdt.append(
    //   "property_name", this.getThisDesignForm1.value.propertyName
    // )
   

    

   
    // formdt.append('marketing_page', 'true');

     //Lead status data
    // if (this.getThisDesignForm3.value.possessionProperty_date && this.getThisDesignForm3.value.startProject_date) {
    //   if (this.getThisDesignForm2.value.scopeOfWork == "Full Home Interiors") {
    //     if (["Mumbai"].includes(this.getThisDesignForm1.value.city) && (["70 - 99 Lacs", "1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.getThisDesignForm2.value.homeValue))) {
    //       formdt.append('lead_status', "not_attempted");
    //     }
    //     else if (["Delhi NCR", "Ghaziabad", "Faridabad"].includes(this.getThisDesignForm1.value.city) && (["1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.getThisDesignForm2.value.homeValue))) {
    //       formdt.append('lead_status', "not_attempted");
    //     }
    //     else if (["Bangalore", "Pune"].includes(this.getThisDesignForm1.value.city) && (["30 - 50 Lacs", "50 - 70 Lacs", "70 - 99 Lacs", "1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.getThisDesignForm2.value.homeValue))) {
    //       formdt.append('lead_status', "not_attempted");
    //     }
    //     else{
    //       formdt.append('lead_status', "not_attempted");
    //     }
    //   }
    //   else {
    //     if (this.getThisDesignForm3.value.yourBudge != "Less than 1.5 Lac") {
    //       if (["Mumbai"].includes(this.getThisDesignForm1.value.city) && (["70 - 99 Lacs", "1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.getThisDesignForm2.value.homeValue))) {
    //         formdt.append('lead_status', "not_attempted");
    //       }
    //       else if (["Delhi NCR", "Ghaziabad", "Faridabad"].includes(this.getThisDesignForm1.value.city) && (["1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.getThisDesignForm2.value.homeValue))) {
    //         formdt.append('lead_status', "not_attempted");
    //       }
    //       else if (["Bangalore", "Pune", "Chennai", "Aurangabad", "Ahmednagar", "Kochi"].includes(this.getThisDesignForm1.value.city) && (["30 - 50 Lacs", "50 - 70 Lacs", "70 - 99 Lacs", "1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "Above 2.50 Cr"].includes(this.getThisDesignForm2.value.homeValue))) {
    //         formdt.append('lead_status', "not_attempted");
    //       }
    //       else{
    //         formdt.append('lead_status', "not_attempted");
    //       }
    //     }
    //   }
    // }
    // else {
    //   formdt.delete('lead_status');
    //   formdt.append('lead_status', "not_attempted");
    // }
     //Lead status data end


    // formdt.append(
    //   "possession_status",this.getThisDesignForm3.value.possessionProperty_date ? 'new':''
    // )
    // formdt.append(
    //   "from_fasttrack_page", 'true'
    // )

    // formdt.append(
    //   "lead_questionaire_items_attributes", JSON.stringify(this.num === 2 ? this.items != undefined ? this.items : 'null' : this.all_items != undefined ? this.all_items : 'null')
    // )
    // this.apartmentservice.updateleadstatus(this.leadData.lead.id,formdt).subscribe(res =>{
    //   this.loader = false;
    //   this.toastr.success("Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours");
    //   this.updateLeadData = res;
    //   this.modal.close(res);
    // }, error =>{
    //   this.loader = false
    //   this.modal.close();
    //   this.toastr.error(error.error.message);
    // });

    let leadData:any = [
      // form 1 fields
      { "Attribute": "FirstName", "Value":  this.getThisDesignForm1.value.fullName },
      { "Attribute": "mx_Lead_Name", "Value": this.getThisDesignForm1.value.fullName },
      { "Attribute": "Phone", "Value": this.getThisDesignForm1.value.mobileNumber },
      { "Attribute": "EmailAddress", "Value": this.getThisDesignForm1.value.emailAddress },
      { "Attribute": "mx_Society_Name", "Value": this.getThisDesignForm1.value.propertyName ? this.getThisDesignForm1.value.propertyName :'' },
      { "Attribute": "mx_Location", "Value": this.getThisDesignForm1.value.Locality },
      { "Attribute": "mx_City", "Value": this.getThisDesignForm1.value.city},
      { "Attribute": "mx_Zip", "Value": this.getThisDesignForm1.value.pincode },
      
      // form 2 fields
      { "Attribute": "mx_Scope_of_Work", "Value": this.getThisDesignForm2.value.scopeOfWork },
      { "Attribute": "mx_Type_of_Accommodation", "Value": this.getThisDesignForm2.value.accomandationType },
      { "Attribute": "mx_Home_Value", "Value": this.getThisDesignForm2.value.homeValue ? this.getThisDesignForm2.value.homeValue : '' },
      { "Attribute": "mx_Floor_plan_status", "Value": this.getThisDesignForm2.value.doYouHaveFloorPlan  },
      //file is pending
      
      
      // form 3 fields
      { "Attribute": "mx_What_are_you_looking_for", "Value": this.getThisDesignForm3.value.lookingFor },
      { "Attribute": "mx_When_do_you_get_the_possession_of_the_property", "Value": this.getThisDesignForm3.value.possessionProperty_date ? 'Awaiting Possession'  : 'Possession Taken' },
      { "Attribute": "mx_When_do_you_want_to_start_the_project", "Value": this.getThisDesignForm3.value.startProject_date },
      { "Attribute": "mx1_Budget_Value", "Value": this.getThisDesignForm3.value.yourBudge }, // it needs to be an number
      // When do you want to start the project? needs to add
      // mx_When_do_you_want_to_start_the_project
      // 

      // form 4 fields
      { "Attribute": "mx_Do_you_have_a_home_loan_against_the_property", "Value": this.getThisDesignForm4.value.existingHomeLone == 'Yes' ? true : false},
      { "Attribute": "mx_Financial_Assisstance", "Value": this.getThisDesignForm4.value.financialassistance == 'Yes' ? true : false},
      { "Attribute": "mx_Purpose_Of_Property", "Value": this.getThisDesignForm4.value.purposeProperty},

      { "Attribute": "Notes", "Value": this.getThisDesignForm4.value.tellMeAboutYourself || '' },
      // Would you like any financial assistance?
      // Purpose Of Property
      // 
      
      // Extra keys
      { "Attribute": "Source", "Value": "website" },
      { "Attribute": "SourceCampaign", "Value": "" },
      { "Attribute": "mx_UTM_Medium", "Value": "" },
      { "Attribute": "mx_UTM_Term", "Value": "" },
      { "Attribute": "mx_UTM_Content", "Value": "" },
      { "Attribute": "mx_Apartment_ID", "Value": this.autoSeelectedId ? this.autoSeelectedId : '' },
      { "Attribute": "mx_Is_MarketingPage", "Value": true },
      { "Attribute": "mx_Lead_Status",  "Value": "not_attempted" }      
      
    ];

        
      this.apartmentservice.createBookConsultation({'leads': leadData}).subscribe(res => {
        this.leadData = res;
        this.loader= false
        this.toastr.success("Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours");
        this.modal.close();
      }, error =>  {
        this.toastr.error(error.error.message);
         this.modal.close();
         this.loader = false
      });
  }




  

  cancelTwo() {
    this.modal.close();
  }
  nextTwo() {
  }
  cancelThree() {
    this.modal.close();
  }
  nextThree() {
  }
  cancelFour() {
    this.modal.close();
  }
  submit() {
  }


  // ------------ Manually data for dropdowns (dont change any values)------------

  getNext12MonthNamesWithYear() {
    var now = new Date();
    var month = now.getMonth();
    var year = now.getFullYear();
    var names = ['January', 'February', 'March', 'April', 'May', 'June',
                 'July', 'August', 'September', 'October', 'November', 'December'];
        this.possession_date = [];
        for (var i = 0; i < 13; ++i) {
            this.possession_date.push(names[month] + ' ' + year);
            if (++month === 12) {
                month = 0;
                ++year;
            }
        }
        this.project_start_date = this.possession_date;
        return this.possession_date;
    }

  CityData: any = [
    {id:0, cityname:"Delhi NCR"},
    {id:1, cityname:"Mumbai"},
    {id:2, cityname:"Pune"},
    {id:3, cityname:"Bangalore"},
    {id:4, cityname:"Chennai"},
    {id:5, cityname:"Chandigarh"},
    {id:6, cityname:"Ahmedabad"},
    {id:7, cityname:"Kochi"},
    {id:8, cityname:"Aurangabad"},
    {id:9, cityname:"Trivandrum"},
    {id:10, cityname:"Calicut"},
    {id:11, cityname:"Faridabad"}
  ]

  ScopeWorkData: any = [
    { id: 0, scopeworkname: 'Modular Kitchen' },
    { id: 1, scopeworkname: 'Furniture & MTO (Includes False Ceiling/Painting)' },
    { id: 2, scopeworkname:  'FHI (Includes Services)' },
    
  ];


  AccomandationTypeData: any = [
    {id:0, accomandationtname:"1RK"},
    {id:1, accomandationtname:"1BHK"},
    {id:2, accomandationtname:"1.5BHK"},
    {id:3, accomandationtname:"2BHK"},
    {id:4, accomandationtname:"2.5BHK"},
    {id:5, accomandationtname:"3BHK"},
    {id:6, accomandationtname:"3.5BHK"},
    {id:7, accomandationtname:"4BHK"},
    {id:8, accomandationtname:"4.5BHK"},
    {id:9, accomandationtname:"5BHK"},
    {id:10, accomandationtname:"Villa"},
    {id:11, accomandationtname:"Bungalow"},

  ]

  PurposeOfProperty: any = [
    { id:0, value:'Own Stay'},
    { id:1, value:'Rental' },
    { id:2, value:'Not Disclosed'},
 ]

  HomeValueData: any = [
    {id:0, homevalue:"Less than 30 Lacs"},
    {id:1, homevalue:"30 - 50 Lacs"},
    {id:2, homevalue:"50 - 70 Lacs"},
    {id:3, homevalue:"70 - 99 Lacs"},
    {id:4, homevalue:"1 - 1.25 Cr"},
    {id:5, homevalue:"1.26 - 1.75 Cr"},
    {id:6, homevalue:"1.76 - 2.50 Cr"},
    {id:7, homevalue:"2.5 Cr - 4.00 Cr"},
    {id:8, homevalue:"Above 4.00 Cr"},
  ]

   LookingForData: any = [
     {id:1, value:'New (No one has ever stayed in that home)'},
     {id:1, value:'Old (Currently staying in the house)'}
   ]

   BudgeValueData:any = [
    {id:0, budgevalue:"Less than 1.5 Lacs"},
    {id:0, budgevalue:"1.5 - 3 Lacs"},
    {id:0, budgevalue:"3 - 5 Lacs"},
    {id:0, budgevalue:"5 - 8 Lacs"},
    {id:0, budgevalue:"8 - 12 Lacs"},
    {id:0, budgevalue:"Above 12 Lacs"},
    {id:0, budgevalue:"Not Disclosed"},
   ]

   homeloadData:any = [
     {id:1, value: "Yes"},
     {id:2, value: "No"}
   ]
   financialassistanceData: any = [
    {id:1, value: "Yes"},
    {id:2, value: "No"}
   ]



   email_validation:boolean = false;
  errormsgname: boolean = false;
  errormsgmobile: boolean = false;
  firstValidation(){
    var email = /^[(A-Z)(a-z)0-9._%+-]+@[(A-Z)(a-z)0-9.-]+\.[(a-z)(A-Z)]{2,4}$/;
    if (this.getThisDesignForm1.valid) {
      if (this.getThisDesignForm1.value.emailAddress) {
        if (this.getThisDesignForm1.value.emailAddress.match(email)) {
          this.email_validation = false;
          this.errormsgname = false;
          this.errormsgmobile = false;
          this.checkprecence(this.getThisDesignForm1.value.propertyName)                    
          this.nextOne('pills-2','pills-1','pills-2-tab','pills-1-tab');
          designpersondetail(
            this.getThisDesignForm1.value.fullName,
            this.getThisDesignForm1.value.mobileNumber,
            this.getThisDesignForm1.value.emailAddress,
            this.getThisDesignForm1.value.propertyName,
            this.getThisDesignForm1.value.pincode,
          );
        }
        else {
          this.email_validation = true;
                  } 
      }
      else {
        this.checkprecence(this.getThisDesignForm1.value.propertyName) 
        this.email_validation = false;
        this.errormsgname = false;
        this.errormsgmobile = false;
       this.nextOne('pills-2','pills-1','pills-2-tab','pills-1-tab');
      }
    }else{
      this.errormsgname = true;
      this.errormsgmobile = true;
    }
  }
  secondValidation(){
    if (this.getThisDesignForm2.valid) {
     propertyDetail(
       this.apartment_name,
       this.autoSelectedBHK,
       this.getThisDesignForm2.value.homeValue
     );
      this.nextOne('pills-3','pills-2','pills-3-tab','pills-2-tab');
  
    }
  
  }
  thirdValidation(){
    // if (this.getThisDesignForm3.value.lookingFor) {
      possesionDetail(
        this.getThisDesignForm3.value.lookingFor,
        this.getThisDesignForm3.value.possessionProperty_date,
        this.getThisDesignForm3.value.startProject_date,
        this.getThisDesignForm3.value.yourBudge
      );
      this.nextOne('pills-4','pills-3','pills-4-tab','pills-3-tab');
    // }else{
    //   this.toastr.error('Please Enter Valid Data');
    // }
  }

  nextOne(next:any,prev:any,activetab:any,deactivetab:any) {
      $('#'+next).show();
      $('#'+prev).hide();
      $("#"+activetab).addClass("active");
  }
  nextOne1(next:any,prev:any,activetab:any,deactivetab:any) {
      $('#'+next).show();
      $('#'+prev).hide();
      $("#"+activetab).addClass("active");
     $("#"+deactivetab).removeClass("active");
  }

  nextOne2(next:any,prev:any,activetab:any,deactivetab:any) {
      $('#'+next).show();
      $('#'+prev).hide();
      $("#"+activetab).addClass("active");
     $("#"+deactivetab).removeClass("active");
  }

  nextOne3(next:any,prev:any,activetab:any,deactivetab:any) {
      $('#'+next).show();
      $('#'+prev).hide();
      $("#"+activetab).addClass("active");
     $("#"+deactivetab).removeClass("active");
  }



  filteredOptions:any
  othersShow = false;
  isSelected = false;
  Typeselect:any;
  isApifired = false

  projectName2 ='';
  DeveloperName2:any;
  pincodePro2 ='';
  FullAdress2:any;
  citynamePro2:any;
  AidApartmentID:any;
  formattedaddress:any
  PropertyName:any
  searchSocietyApi($event:any) {
    this.othersShow = true;
    this.isApifired = true;
    this.Typeselect = ''
    this.isSelected = false
    let searchWord = $event.target.value;
    this.apartmentservice.getSocietyWebapi(searchWord).subscribe(
     ( res:any )=> {
        this.filteredOptions = res.result
        console.log(this.filteredOptions)


      },
      err => {
        this.filteredOptions = []

      }
    );
  }



  selectOption(address:any){
    this.googleApihiteed=true

    this.filteredOptions = []
    this.isSelected = true
    if(address == 'other'){
      $('#OtherprojectName3').modal('show');
      this.projectName2 = this.getThisDesignForm1.controls['propertyName'].value;
      this.DeveloperName2 =''
      this.FullAdress2 ='';
      this.citynamePro2 = '';
      this.pincodePro2 = ''
      this.AidApartmentID = ''

    } else{
      console.log(address)
      let location = address.apartment_name +','+address.area_name
      this.getThisDesignForm1.controls['propertyName'].setValue(address.apartment_name)
      this.projectName2 = this.getThisDesignForm1.controls['propertyName'].value;
      this.getThisDesignForm1.controls['city'].setValue(address.city)
      this.getThisDesignForm1.controls['pincode'].setValue(address.pincode)
      this.getThisDesignForm1.controls['Locality'].setValue(address.area_name)
      this.getThisDesignForm1.controls['apartment'].setValue(location)

     
      this.AidApartmentID = address.id
    }


  }
  hideOptions(){
    this.isApifired = false
  }

  showOptions(){
    this.isApifired = true
  }
areaname:any;
  Submitapart(){
    $('#OtherprojectName3').modal('hide');
    let area = this.areaname?this.areaname:this.FullAdress2
    let location = this.projectName2+',' +area ;

    this.getThisDesignForm1.controls['city'].setValue(this.citynamePro2)
    this.getThisDesignForm1.controls['pincode'].setValue(this.pincodePro2)
    this.getThisDesignForm1.controls['propertyName'].setValue(this.projectName2)
    this.getThisDesignForm1.controls['Locality'].setValue(area)
    this.getThisDesignForm1.controls['apartment'].setValue(location)

    this.CreateApartment()
  }

 
  handleAddressChange(address: any) {
    this.PropertyName = address.name
    this.FullAdress2 =  this.PropertyName
    this.formattedaddress = address.name;
  
    this.formattedaddress = address.name + "," + address.formatted_address;
    var str = this.formattedaddress;
    var arr = [];
    arr = str.split(",");
    var unique = [];
    for (i = 0; i < arr.length; i++) {
      if ((i == arr.indexOf(arr[i])) || (arr.indexOf(arr[i]) == arr.lastIndexOf(arr[i])))
        unique.push(arr[i]);
    }
    this.formattedaddress = unique.join(",");

    console.log(this.formattedaddress);
    console.log(address);
    for (var i = 0; i < address.address_components.length; i++) {
    
      for (var j = 0; j < address.address_components[i].types.length; j++) {
       
        if (address.address_components[i].types[j] == 'postal_code') {
         
        
          console.log(address.address_components[i].long_name);
          
        ;
        this.pincodePro2 =  address.address_components[i].long_name
      
      
          
        } else{
          this.pincodePro2 = ''
        }

       
      this.FullAdress2 = this.formattedaddress;
      var areaName = address.address_components.filter(
        (component:any) => component.types.includes('sublocality')
      );
      const concatenatedNames = areaName.map((component:any) => component.long_name).join(', ');
      areaName = concatenatedNames
     this.areaname=  areaName ?areaName : this.formattedaddress;
            if (address.address_components[i].types[j] == 'locality') {
              this.getThisDesignForm1.controls['city'].setValue(
                address.address_components[i].long_name
              );
              this.citynamePro2 =  address.address_components[i].long_name
              console.log(this.getThisDesignForm1.controls['city'].value);
              console.log(address.address_components[i].long_name);

            } 

      }
    }

  } 

  CreateApartment(){
    let address = this.getThisDesignForm1.controls['apartment'].value;
    const pincodeRegex = /\b\d{6}\b/;
    this.loader = true

// Use the match method to find the first match of the regex pattern in the string
const pincodeMatch = address.match(pincodeRegex);

// Check if a match is found
if (pincodeMatch) {
  // Extracted PIN code
  var pincode = pincodeMatch[0];

} else {
  pincode =''
}

    let obj ={
      "rera_number": "",
      "project_name": this.projectName2,
      "full_address": this.FullAdress2,
      "project_developer": this.DeveloperName2,
      "pincode":  this.getThisDesignForm1.controls['pincode'].value,
      "area_name":this.getThisDesignForm1.controls['Locality'].value,
      "city":  this.getThisDesignForm1.controls['city'].value,
      "state": ""
  }
     this.apartmentservice.CreateApar(obj).subscribe((Res:any)=>{
      this.loader = false
      this.toastr.success('Apartment Created Successfully')
      this.AidApartmentID = Res.result[0].apartment_id	;
     },err=>{
      this.loader = false
    this.toastr.error(JSON.parse(err['_body']).message)
     })


  }

  closeaddApartmentrplanModal(){
    $('#OtherprojectName3').modal('hide');
    this.getThisDesignForm1.controls['pincode'].setValue('')
    this.getThisDesignForm1.controls['city'].setValue('')
    this.getThisDesignForm1.controls['propertyName'].setValue('')

  }

}