import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { error } from 'protractor';
import { ApartmentService } from 'src/app/apartment/apartment.service';
declare var $:any;

@Component({
  selector: 'app-book-consultation-form', 
  templateUrl: './book-consultation-form.component.html',
  styleUrls: ['./book-consultation-form.component.scss'],
})
export class BookConsultationFormComponent implements OnInit {
  page: string = '';
  constructor(
    private modal: NgbActiveModal,
    private fb: FormBuilder,
    private apartmentservice: ApartmentService,
    private toastr: ToastrService
  ) {
  }

  bookConsultationForm1: any = FormGroup;
  bookConsultationForm2: any = FormGroup;
  bookConsultationForm3: any = FormGroup;
  bookConsultationForm4: any = FormGroup;

  disabledCity: boolean = true;
  selectedCityname: any;
  pincodedata: any;

  isChecked: boolean = false;
  leadData: any;
  updateLeadData: any;
  selectedScopeWork: any;
  hidesearch: boolean = false;
  mymodelpincode: any = '';
  mymodelcheckbox: any = '';
  hideFinancialAssistance: boolean = false;
  selectedHomeloadValue: any;
  possession_date: any;
  project_start_date: any;
  errormsgname: boolean = false;
  errormsgmobile: boolean = false;
  loader: boolean = false;

  ngOnInit(): void {
        this.createFirstForm();
    this.createSecondForm();
    this.createThirdForm();
    this.createForthForm();
    // this.bookConsultationForm1.get('city').disable();
    this.getNext12MonthNamesWithYear();
    this.loadScript()
    this.BuildingNameGetter();
    this.disableForm();
    this.buildingvaluesSetter()

  }
  BuildingNameGetter() {
    const url = window.location.href;
    const keywords = [
      'marathon-nexzone',
      '25-south',
      'sky-forest',
      'Tridhaatu-Morya',
      '140-shivaji',
      'Tridhaatu-Aranya',
      'adhiraj-capital-city',
      'Runwal_Gardens',
      'dosti-pine',
      'lodha-amara',
      'Rustomjee-Summit',
       'Birla-Alokya'
    ];
  
    for (const keyword of keywords) {
      if (url.includes(keyword)) {
        if (keyword === '140-shivaji' && url.includes('140-shivaji')) {
          return '140-shivaji2';
        }
        return keyword;
      }
    }
  
    return '';
  }

  isDisabledchecker(){
    if(this.BuildingNameGetter()=='marathon-nexzone' || this.BuildingNameGetter()=='140-shivaji' || this.BuildingNameGetter()=='sky-forest' || this.BuildingNameGetter() == 'Tridhaatu-Morya' || this.BuildingNameGetter() == '140-shivaji2' || this.BuildingNameGetter() == 'Tridhaatu-Aranya' || this.BuildingNameGetter()=='adhiraj-capital-city'  || this.BuildingNameGetter()=='Runwal_Gardens' || this.BuildingNameGetter()=='dosti-pine' || this.BuildingNameGetter()=='lodha-amara' || this.BuildingNameGetter()=='Rustomjee-Summit' || this.BuildingNameGetter()=='Birla-Alokya' || this.BuildingNameGetter()=='25-south'){
   
      return true;
    } else{
      return false;
    }
  }

  disableForm(){
    if(this.isDisabledchecker()){
      // this.bookConsultationForm1.get('city').disable();
      this.bookConsultationForm2.get('accomandationType').disable();
      this.bookConsultationForm2.get('homeValue').disable();

    } else{
      // this.bookConsultationForm1.get('city').enabel();
      this.bookConsultationForm2.get('accomandationType').enable();
      this.bookConsultationForm2.get('homeValue').enable();
    }
  }

  marathonCityValue :any
  marathonaccomandationType : any
  marathonhomeValue :any
  buildingvaluesSetter(){

    if(this.BuildingNameGetter()=='140-shivaji'){
      this.bookConsultationForm1.controls['city'].setValue('Mumbai')
      this.bookConsultationForm1.controls['pincode'].setValue(400028);
      this.bookConsultationForm1.controls['propertyName'].setValue('140 Shivaji Park');
      this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
      this.bookConsultationForm2.controls['accomandationType'].setValue('3BHK')
      this.bookConsultationForm2.controls['homeValue'].setValue('1 - 1.25 Cr')
      console.log(this.bookConsultationForm1.controls['pincode'].value)
      this.marathonCityValue = 'Mumbai'
      this.marathonaccomandationType = '3BHK'
      this.marathonhomeValue = '1 - 1.25 Cr'
      this.checkprecence('140 Shivaji Park')
    } else{
      if(this.BuildingNameGetter()=='marathon-nexzone'){
        this.bookConsultationForm1.controls['city'].setValue('Navi Mumbai')
        this.bookConsultationForm1.controls['pincode'].setValue(410206);
        this.bookConsultationForm1.controls['propertyName'].setValue('Marathon Nexzone');
        this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
        this.bookConsultationForm2.controls['accomandationType'].setValue('1BHK')
        this.bookConsultationForm2.controls['homeValue'].setValue('1.76 - 2.50 Cr')
        this.marathonCityValue = 'Navi Mumbai'
        this.marathonaccomandationType = '1BHK'
        this.marathonhomeValue = '1.76 - 2.50 Cr'
        this.checkprecence('Marathon Nexzone')
      } else{ 
        if(this.BuildingNameGetter()=='sky-forest'){

        this.bookConsultationForm1.controls['city'].setValue('Mumbai')
        this.bookConsultationForm1.controls['pincode'].setValue(400013);
        this.bookConsultationForm1.controls['propertyName'].setValue(this.BuildingNameGetter()=='sky-forest'?'India Bulls Sky Forest':'Adhiraj Capital City');
        this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
        this.bookConsultationForm2.controls['accomandationType'].setValue('3BHK')
        this.bookConsultationForm2.controls['homeValue'].setValue('2.5 Cr - 4.00 Cr')
        this.marathonCityValue = 'Mumbai'
        this.marathonaccomandationType = '3BHK'
        this.marathonhomeValue = '2.5 Cr - 4.00 Cr'
        this.checkprecence('India Bulls Sky Forest')
      } else{ 
          if(this.BuildingNameGetter()=='Tridhaatu-Morya'){

            this.bookConsultationForm1.controls['city'].setValue('Mumbai')
            this.bookConsultationForm1.controls['pincode'].setValue(400088);
            this.bookConsultationForm1.controls['propertyName'].setValue('Tridhaatu Morya');
            this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
            this.bookConsultationForm2.controls['accomandationType'].setValue('1BHK')
            this.bookConsultationForm2.controls['homeValue'].setValue('2.5 Cr - 4.00 Cr')
            this.marathonCityValue = 'Mumbai'
            this.marathonaccomandationType = '1BHK'
            this.marathonhomeValue = '2.5 Cr - 4.00 Cr'
            this.checkprecence('Tridhaatu Morya')
          } else{
            if(this.BuildingNameGetter()=='140-shivaji2'){

              this.bookConsultationForm1.controls['city'].setValue('Mumbai')
              this.bookConsultationForm1.controls['pincode'].setValue(400028);
              this.bookConsultationForm1.controls['propertyName'].setValue('Urbania 140 Shivaji Park');
              this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
              this.bookConsultationForm2.controls['accomandationType'].setValue('3BHK')
              this.bookConsultationForm2.controls['homeValue'].setValue('1 - 1.25 Cr')
              this.marathonCityValue = 'Mumbai'
              this.marathonaccomandationType = '3BHK'
              this.marathonhomeValue = '1 - 1.25 Cr'
              this.checkprecence('Urbania 140 Shivaji Park')
            }else{
                if(this.BuildingNameGetter()=='Tridhaatu-Aranya'){
                  this.bookConsultationForm1.controls['city'].setValue('Mumbai')
                  this.bookConsultationForm1.controls['pincode'].setValue(400088);
                  this.bookConsultationForm1.controls['propertyName'].setValue('Tridhaatu Aranya');
                  this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
                  this.bookConsultationForm2.controls['accomandationType'].setValue('3BHK')
                  this.bookConsultationForm2.controls['homeValue'].setValue('1 - 1.25 Cr')
                  this.marathonCityValue = 'Mumbai'
                  this.marathonaccomandationType = '3BHK'
                  this.marathonhomeValue = '1 - 1.25 Cr'
                  this.checkprecence('Tridhaatu Aranya')
                }else if(this.BuildingNameGetter()=='adhiraj-capital-city') {
                  this.bookConsultationForm1.controls['city'].setValue('Navi Mumbai')
                  this.bookConsultationForm1.controls['pincode'].setValue(410208);
                  this.bookConsultationForm1.controls['propertyName'].setValue('Adhiraj Capital City');
                  this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
                  this.bookConsultationForm2.controls['accomandationType'].setValue('3BHK')
                  this.bookConsultationForm2.controls['homeValue'].setValue('1 - 1.25 Cr')
                  this.marathonCityValue = 'Navi Mumbai'
                  this.marathonaccomandationType = '3BHK'
                  this.marathonhomeValue = '1 - 1.25 Cr'
                  this.checkprecence('Adhiraj Capital City')
                }
                else if(this.BuildingNameGetter()=='Runwal_Gardens') {
                  this.bookConsultationForm1.controls['city'].setValue('Mumbai')
                  this.bookConsultationForm1.controls['pincode'].setValue(421204);
                  this.bookConsultationForm1.controls['propertyName'].setValue('Runwal Gardens');
                  this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
                  this.bookConsultationForm2.controls['accomandationType'].setValue('1.5BHK')
                  this.bookConsultationForm2.controls['homeValue'].setValue('2.5 Cr - 4.00 Cr')
                  this.marathonCityValue = 'Mumbai'
                  this.marathonaccomandationType = '1.5BHK'
                  this.marathonhomeValue = '2.5 Cr - 4.00 Cr'
                  this.checkprecence('Runwal Gardens')
                }
                else if(this.BuildingNameGetter()=='dosti-pine') {
                  this.bookConsultationForm1.controls['city'].setValue('Mumbai')
                  this.bookConsultationForm1.controls['pincode'].setValue(400608);
                  this.bookConsultationForm1.controls['propertyName'].setValue('Dosti Pine');
                  this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
                  this.bookConsultationForm2.controls['accomandationType'].setValue('2BHK')
                  this.bookConsultationForm2.controls['homeValue'].setValue('Above 4.00 Cr')
                  this.marathonCityValue = 'Mumbai'
                  this.marathonaccomandationType = '2BHK'
                  this.marathonhomeValue = 'Above 4.00 Cr'
                  this.checkprecence('Dosti Pine')
                }
                else if(this.BuildingNameGetter()=='lodha-amara') {
                  this.bookConsultationForm1.controls['city'].setValue('Mumbai')
                  this.bookConsultationForm1.controls['pincode'].setValue(400607);
                  this.bookConsultationForm1.controls['propertyName'].setValue('Lodha Amara');
                  this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
                  this.bookConsultationForm2.controls['accomandationType'].setValue('2BHK')
                  this.bookConsultationForm2.controls['homeValue'].setValue('1.26 - 1.75 Cr')
                  this.marathonCityValue = 'Mumbai'
                  this.marathonaccomandationType = '2BHK'
                  this.marathonhomeValue = '1.26 - 1.75 Cr'
                  this.checkprecence('Lodha Amara')
                }
                else if(this.BuildingNameGetter()=='Rustomjee-Summit') {

                  this.bookConsultationForm1.controls['city'].setValue('Mumbai');
                  this.bookConsultationForm1.controls['pincode'].setValue(400066);
                  this.bookConsultationForm1.controls['propertyName'].setValue('Rustomjee Summit');
                  this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
                  this.bookConsultationForm2.controls['accomandationType'].setValue('2BHK');
                  this.bookConsultationForm2.controls['homeValue'].setValue('1.26 - 1.75 Cr');
                  this.marathonCityValue = 'Mumbai';
                  this.marathonaccomandationType = '2BHK';
                  this.marathonhomeValue = '1.26 - 1.75 Cr';
                  this.checkprecence('Rustomjee Summit')
                }
                else if(this.BuildingNameGetter()=='Birla-Alokya') {
                  this.bookConsultationForm1.controls['city'].setValue('Bangalore');
                  this.bookConsultationForm1.controls['pincode'].setValue(560067);
                  this.bookConsultationForm1.controls['propertyName'].setValue('Birla Alokya');
                  this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
                  this.bookConsultationForm2.controls['accomandationType'].setValue('2BHK');
                  this.bookConsultationForm2.controls['homeValue'].setValue('1.26 - 1.75 Cr');
                  this.marathonCityValue = 'Bangalore';
                  this.marathonaccomandationType = '2BHK';
                  this.marathonhomeValue = '1.26 - 1.75 Cr';
                  this.checkprecence('Birla Alokya')
                  


               

                }
                else if(this.BuildingNameGetter()=='25-south') {
                  this.bookConsultationForm1.controls['city'].setValue('Mumbai');
                  this.bookConsultationForm1.controls['pincode'].setValue(560067);
                  this.bookConsultationForm1.controls['propertyName'].setValue('25 South');
                  this.bookConsultationForm1.controls['apartment'].setValue(this.bookConsultationForm1.controls['propertyName'].value)
                  this.bookConsultationForm2.controls['accomandationType'].setValue('3BHK');
                  this.bookConsultationForm2.controls['homeValue'].setValue('1.26 - 1.75 Cr');
                  this.marathonCityValue = 'Mumbai';
                  this.marathonaccomandationType = '3BHK';
                  this.marathonhomeValue = '1.26 - 1.75 Cr';
                  this.checkprecence('25 South')
                  


               

                }
                
            }
          }
        }
        


      }
      
    }

  }

  resultaddress:any

  checkprecence(apart_name: any) {
    return this.apartmentservice.checkprecence(apart_name).subscribe(
      (res: any) => {
        console.log("hiiii");
        console.log(res.result.length);
       
        if(res.result.length > 0)
        {
        this.resultaddress=res.result[0]
        let address =this.resultaddress 
        this.marathonCityValue = this.resultaddress.city
        let location=address.apartment_name+","+address.area_name
        this.bookConsultationForm1.controls['propertyName'].setValue(address.apartment_name)
        this.projectName2 = this.bookConsultationForm1.controls['propertyName'].value;
        this.bookConsultationForm1.controls['city'].setValue(address.city)
        this.bookConsultationForm1.controls['pincode'].setValue(address.pincode)
        this.bookConsultationForm1.controls['Locality'].setValue(address.area_name)
        this.bookConsultationForm1.controls['apartment'].setValue(location)
        }
      

      },
      (error: any) => {
        console.error(error);
      
       

      
  });
  }
  onBlur(event: any) {
    if (event.target.value.length === 10) {
      this.errormsgname = false;
    }
  }
  onBlurName(event: any) {
    if (event.target.value !== '') {
      this.errormsgmobile = false;
    }
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  activbtn: any = {
    pillsActiveOne: true,
    pillsActiveTwo: false,
    pillsActiveThree: false,
    pillsActiveFour: false,
  };

  changePillsOne() {
    this.activbtn.pillsActiveOne = true;
    this.activbtn.pillsActiveTwo = false;
    this.activbtn.pillsActiveThree = false;
    this.activbtn.pillsActiveTwo = false;
  }

  changePillsTwo() {
    this.activbtn.pillsActiveOne = true;
    this.activbtn.pillsActiveTwo = true;
    this.activbtn.pillsActiveThree = false;
    this.activbtn.pillsActiveTwo = false;
  }

  changePillsThree() {
    this.activbtn.pillsActiveOne = true;
    this.activbtn.pillsActiveTwo = true;
    this.activbtn.pillsActiveThree = true;
    this.activbtn.pillsActiveTwo = false;
  }

  changePillsFour() {
    this.activbtn.pillsActiveOne = true;
    this.activbtn.pillsActiveTwo = true;
    this.activbtn.pillsActiveThree = true;
    this.activbtn.pillsActiveTwo = true;
  }

  createFirstForm() {
    this.bookConsultationForm1 = this.fb.group({
      fullName: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      emailAddress: [''],
      propertyName: [''],
      Locality:[''],
      city: [''],
      apartment:[''],
      pincode: [''],
    });
  }

  get f() {
    return this.bookConsultationForm1.controls;
  }

  createSecondForm() {
    this.bookConsultationForm2 = this.fb.group({
      scopeOfWork: [''],
      accomandationType: [''],
      homeValue: [''],
      doYouHaveFloorPlan: [''],
      uploadfloorPlanFile: [''],
    });
  }

  createThirdForm() {
    this.bookConsultationForm3 = this.fb.group({
      lookingFor: [''],
      possessionProperty_date: [''],
      startProject_date: [''],
      yourBudge: [''],
    });
  }

  createForthForm() {
    this.bookConsultationForm4 = this.fb.group({
      existingHomeLone: [''],
      financialassistance: [''],
      purposeProperty: [''],
      tellMeAboutYourself: [''],
    });
  }

  onChangehomeloanvalue(event: any) {
    this.selectedHomeloadValue = event.target.value;
    if (this.selectedHomeloadValue === 'Yes') {
      this.hideFinancialAssistance = true;
    } else {
      this.hideFinancialAssistance = false;
    }
  }

  // scopeWork(event: any) {
  //   this.selectedScopeWork = event.target.value;
  //   if (!this.selectedScopeWork || this.isDisabledchecker()) {
  //     this.bookConsultationForm1.get('city').disable();
  //   } else {
  //     this.bookConsultationForm1.get('city').enable();
  //   }
  // }

  selectedCity(event: any) {
    this.selectedCityname = event.target.value;
    this.apartmentservice.getPincode(this.selectedCityname).subscribe((res) => {
      this.pincodedata = res;
    });
  }

  valuechange(newValue: any) {
    this.mymodelpincode = newValue;
    if (!this.mymodelpincode) {
      this.hidesearch = false;
    } else {
      this.hidesearch = true;
    }
  }

  mymodelcheckboxYes: boolean = true;
  mymodelcheckboxNo: boolean = false;
  radiobuttonUploadCondition: boolean = false;
  valuechangeUploadYes(event: any) {
    this.mymodelcheckboxYes = event;
    this.radiobuttonUploadCondition = true;
  }

  valuechangeUploadNo(event: any) {
    this.mymodelcheckboxNo = event;
    this.radiobuttonUploadCondition = false;
  }

  valuechangeUploadCheckbox(event: any) {
    this.mymodelcheckbox = event;
  }

  cancelOne() {
    this.modal.close();
  }

  email_validation: boolean = false;
  firstValidation() {
    var email = /^[(A-Z)(a-z)0-9._%+-]+@[(A-Z)(a-z)0-9.-]+\.[(a-z)(A-Z)]{2,4}$/;
    if (this.bookConsultationForm1.valid) {
      if (this.bookConsultationForm1.value.emailAddress) {
        if (this.bookConsultationForm1.value.emailAddress.match(email)) {
          this.email_validation = false;
          this.errormsgname = false;
          this.errormsgmobile = false;
          // this.onSubmit()                    
          this.nextOne('pills-2', 'pills-1', 'pills-2-tab', 'pills-1-tab');
        } else {
          this.email_validation = true;
        }
      } else {
        // this.onSubmit()    
        this.email_validation = false;
        this.errormsgname = false;
        this.errormsgmobile = true;
        this.nextOne('pills-2', 'pills-1', 'pills-2-tab', 'pills-1-tab');
      }
    } else {
      this.errormsgname = true;
      this.errormsgmobile = true;
    }
  }
  secondValidation() {
    if (this.bookConsultationForm2.valid) {
    this.nextOne('pills-3', 'pills-2', 'pills-3-tab', 'pills-2-tab');
    }
   
  }
  thirdValidation() {
    this.nextOne('pills-4', 'pills-3', 'pills-4-tab', 'pills-3-tab');
  }

  nextOne(next: any, prev: any, activetab: any, deactivetab: any) {
    $('#' + next).show();
    $('#' + prev).hide();
    $('#' + activetab).addClass('active');
  }
  nextOne1(next: any, prev: any, activetab: any, deactivetab: any) {
    $('#' + next).show();
    $('#' + prev).hide();
    $('#' + activetab).addClass('active');
    $('#' + deactivetab).removeClass('active');
  }

  nextOne2(next: any, prev: any, activetab: any, deactivetab: any) {
    $('#' + next).show();
    $('#' + prev).hide();
    $('#' + activetab).addClass('active');
    $('#' + deactivetab).removeClass('active');
  }

  nextOne3(next: any, prev: any, activetab: any, deactivetab: any) {
    $('#' + next).show();
    $('#' + prev).hide();
    $('#' + activetab).addClass('active');
    $('#' + deactivetab).removeClass('active');
  }

  onSubmit() {
    console.log(this.bookConsultationForm1.value.propertyName,this.bookConsultationForm1.value.apartment,this.bookConsultationForm1.value.pincode)
        this.loader = true;
    const postObj = {
      lead: {
        name: this.bookConsultationForm1.value.fullName,
        contact: this.bookConsultationForm1.value.mobileNumber,
        lead_source: 'website',
        lead_campaign: '',
        lead_utm_medium: '',
        lead_utm_term: '',
        lead_utm_content: '',
        email: this.bookConsultationForm1.value.emailAddress,
        scope_of_work: this.selectedScopeWork,
        city: this.bookConsultationForm1.value.city ? this.bookConsultationForm1.value.city : this.marathonCityValue ,
        pincode: this.bookConsultationForm1.value.pincode,
        location: this.bookConsultationForm1.value.apartment,
        property_name: this.bookConsultationForm1.value.propertyName,
        additional_comments:
          this.bookConsultationForm4.value.tellMeAboutYourself || '',
          aide_apartment_id: this.AidApartmentID,
          marketing_page: true
      },
    };

    console.log(location.origin+'/' === window.location.href)

    if(location.origin+'/' === window.location.href){
        postObj.lead['lead_campaign'] = 'Homepage'
    }else {
      postObj.lead['lead_campaign'] = ''
    }

    if (this.bookConsultationForm1.valid) {
      this.apartmentservice.createBookConsultation(postObj).subscribe(
        (res) => {
          this.leadData = res;
          // this.modal.close();
          this.toastr.success(
            'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours'
          );
          
          this.loader = false
          if (this.leadData.lead.duplicate === false) {
            if (
              this.bookConsultationForm1.value.emailAddress ||
              this.bookConsultationForm1.value.scopeOfWork ||
              this.bookConsultationForm1.value.city ||
              this.bookConsultationForm1.value.pincode ||
              this.bookConsultationForm3.value.possessionProperty_date ||
              this.bookConsultationForm3.value.startProject_date ||
              this.bookConsultationForm1.value.propertyName ||
              this.bookConsultationForm4.value.purposeProperty ||
              this.bookConsultationForm3.value.yourBudge ||
              this.bookConsultationForm2.value.accomandationType ||
              this.bookConsultationForm2.value.homeValue ||
              this.bookConsultationForm4.value.existingHomeLone ||
              this.bookConsultationForm4.value.tellMeAboutYourself ||
              this.fileupload
            ) {
            } else {
              this.toastr.success(
                'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours'
              );
            }
          } else {
            this.loader = false;
            this.toastr.error('User Already Exist');
          }
        },
        (error) => {
          this.toastr.error(error.error.message);
          this.modal.close();
        }
      );
    } else {
      this.toastr.error('Please enter the valid details');
    }
  }

  fileupload: any;
  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.fileupload = event.target.files[0];
    }
  }

  filteredOptions:any
  othersShow = false;
  isSelected = false;
  Typeselect:any;
  isApifired = false

  projectName2 ='';
  DeveloperName2:any;
  pincodePro2 ='';
  FullAdress2:any;
  citynamePro2:any;
  AidApartmentID:any;
  formattedaddress:any
  PropertyName:any
  searchSocietyApi($event:any) {
    this.othersShow = true;
    this.isApifired = true;
    this.Typeselect = ''
    this.isSelected = false
    let searchWord = $event.target.value;
    this.apartmentservice.getSocietyWebapi(searchWord).subscribe(
     ( res:any )=> {
        this.filteredOptions = res.result
        console.log(this.filteredOptions)


      },
      err => {
        this.filteredOptions = []

      }
    );
  }



  selectOption(address:any){

    this.filteredOptions = []
    this.isSelected = true
    if(address == 'other'){
      $('#OtherprojectName3').modal('show');
      this.projectName2 = this.bookConsultationForm1.controls['propertyName'].value;
      this.DeveloperName2 =''
      this.FullAdress2 ='';
      this.citynamePro2 = '';
      this.pincodePro2 = ''
      this.AidApartmentID = ''

    } else{
      console.log(address)
      let location=address.apartment_name+","+address.area_name
      this.bookConsultationForm1.controls['propertyName'].setValue(address.apartment_name)
      this.projectName2 = this.bookConsultationForm1.controls['propertyName'].value;
      this.bookConsultationForm1.controls['city'].setValue(address.city)
      this.bookConsultationForm1.controls['pincode'].setValue(address.pincode)
      this.bookConsultationForm1.controls['Locality'].setValue(address.area_name)
      this.bookConsultationForm1.controls['apartment'].setValue(location)

     
      this.AidApartmentID = address.id
    }


  }
  hideOptions(){
    this.isApifired = false
  }

  showOptions(){
    this.isApifired = true
  }
areaname:any;
  Submitapart(){
    $('#OtherprojectName3').modal('hide');
    let area = this.areaname?this.areaname:this.FullAdress2
    let location = this.projectName2+','+ area ;

    this.bookConsultationForm1.controls['city'].setValue(this.citynamePro2)
    this.bookConsultationForm1.controls['pincode'].setValue(this.pincodePro2)
    this.bookConsultationForm1.controls['propertyName'].setValue(this.projectName2)
    this.bookConsultationForm1.controls['Locality'].setValue(area)
    this.bookConsultationForm1.controls['apartment'].setValue(location)


    this.CreateApartment()
  }

 
  handleAddressChange(address: any) {
    this.PropertyName = address.name
    this.FullAdress2 =  this.PropertyName
    this.formattedaddress = address.name;
  
    this.formattedaddress = address.name + "," + address.formatted_address;
    var str = this.formattedaddress;
    var arr = [];
    arr = str.split(",");
    var unique = [];
    for (i = 0; i < arr.length; i++) {
      if ((i == arr.indexOf(arr[i])) || (arr.indexOf(arr[i]) == arr.lastIndexOf(arr[i])))
        unique.push(arr[i]);
    }
    this.formattedaddress = unique.join(",");

    console.log(this.formattedaddress);
    console.log(address);
    for (var i = 0; i < address.address_components.length; i++) {
    
      for (var j = 0; j < address.address_components[i].types.length; j++) {
       
        if (address.address_components[i].types[j] == 'postal_code') {
         
        
          console.log(address.address_components[i].long_name);
          
        ;
        this.pincodePro2 =  address.address_components[i].long_name
      
      
          
        } else{
          this.pincodePro2 = ''
        }

       
      this.FullAdress2 = this.formattedaddress;
      var areaName = address.address_components.filter(
        (component:any) => component.types.includes('sublocality')
      );
      const concatenatedNames = areaName.map((component:any) => component.long_name).join(', ');
      areaName = concatenatedNames
     this.areaname=  areaName ?areaName : this.formattedaddress;
            if (address.address_components[i].types[j] == 'locality') {
              this.bookConsultationForm1.controls['city'].setValue(
                address.address_components[i].long_name
              );
              this.citynamePro2 =  address.address_components[i].long_name
              console.log(this.bookConsultationForm1.controls['city'].value);
              console.log(address.address_components[i].long_name);

            } 

      }
    }

  } 

  CreateApartment(){
    console.log("hiii")
    let address = this.bookConsultationForm1.controls['apartment'].value;
    const pincodeRegex = /\b\d{6}\b/;
    this.loader = true

// Use the match method to find the first match of the regex pattern in the string
const pincodeMatch = address.match(pincodeRegex);

// Check if a match is found
if (pincodeMatch) {
  // Extracted PIN code
  var pincode = pincodeMatch[0];

} else {
  pincode =''
}

    let obj ={
      "rera_number": "",
      "project_name": this.projectName2,
      "full_address": this.FullAdress2,
      "project_developer": this.DeveloperName2,
      "pincode":  this.bookConsultationForm1.controls['pincode'].value,
      "area_name":this.bookConsultationForm1.controls['Locality'].value,
      "city":  this.bookConsultationForm1.controls['city'].value,
      "state": ""
  }
     this.apartmentservice.CreateApar(obj).subscribe((Res:any)=>{
      this.loader = false
      this.toastr.success('Apartment Created Successfully')
      this.AidApartmentID = Res.result[0].apartment_id	;
     },err=>{
      this.loader = false
    this.toastr.error(JSON.parse(err['_body']).message)
     })


  }

  closeaddApartmentrplanModal(){
    $('#OtherprojectName3').modal('hide');
    this.bookConsultationForm1.controls['pincode'].setValue('')
    this.bookConsultationForm1.controls['city'].setValue('')
    this.bookConsultationForm1.controls['propertyName'].setValue('')

  }

  updateleadstatusdata() {
    this.loader = true
    const formData = new FormData();
    formData.append('file', this.fileupload);

    // let formdt = new FormData();

    // formdt.append('lead_campaign', 'null');
    // formdt.append('lead_source', 'website');
    // formdt.append('email', this.bookConsultationForm1.value.emailAddress);
    // formdt.append(
    //   'scope_of_work',
    //   this.bookConsultationForm2.value.scopeOfWork
    // );
    // formdt.append('city', this.bookConsultationForm1.value.city ? this.bookConsultationForm1.value.city: this.marathonCityValue);
    // formdt.append('location', this.bookConsultationForm1.value.apartment);
    // formdt.append('pincode', this.bookConsultationForm1.value.pincode);
    // formdt.append(
    //   'possession_status_date',
    //   this.bookConsultationForm3.value.possessionProperty_date
    // );
    // formdt.append('marketing_page', 'true');

    // formdt.append(
    //   'project_start_date',
    //   this.bookConsultationForm3.value.startProject_date
    // );
    // formdt.append(
    //   'property_name',
    //   this.bookConsultationForm1.value.propertyName
    // );
    // formdt.append(
    //   'customer_budget',
    //   this.bookConsultationForm3.value.yourBudge
    // );
    // formdt.append(
    //   'accomodation_type',
    //   this.bookConsultationForm2.value.accomandationType ? this.bookConsultationForm2.value.accomandationType : this.marathonaccomandationType
    // );
    // formdt.append(
    //   'purpose_of_property',
    //   this.bookConsultationForm4.value.purposeProperty
    // );
    // formdt.append('home_value', this.bookConsultationForm2.value.homeValue ? this.bookConsultationForm2.value.homeValue : this.marathonhomeValue  );
    // formdt.append(
    //   'have_home_loan',
    //   this.bookConsultationForm4.value.existingHomeLone
    // );
    // formdt.append(
    //   'financial_solution_required',
    //   this.bookConsultationForm4.value.existingHomeLone === 'Yes'
    //     ? this.bookConsultationForm4.value.financialassistance
    //     : null
    // );
    // formdt.append(
    //   'additional_comments',
    //   this.bookConsultationForm4.value.tellMeAboutYourself
    // );
    // formdt.append(
    //   'have_floorplan',
    //   this.radiobuttonUploadCondition === true ? 'Yes' : 'No'
    // );
    // formdt.append(
    //   'home_type',
    //   this.bookConsultationForm3.value.possessionProperty_date
    //     ? 'New (No one has ever stayed in that home)'
    //     : 'Old (Currently staying in the house)'
    // );
    // //Lead status data
    // if (
    //   this.bookConsultationForm3.value.possessionProperty_date &&
    //   this.bookConsultationForm3.value.startProject_date
    // ) {
    //   if (
    //     this.bookConsultationForm1.value.scopeOfWork == 'Full Home Interiors'
    //   ) {
    //     if (
    //       ['Mumbai'].includes(this.bookConsultationForm1.value.city) &&
    //       [
    //         '70 - 99 Lacs',
    //         '1 - 1.25 Cr',
    //         '1.26 - 1.75 Cr',
    //         '1.76 - 2.50 Cr',
    //         '2.5 Cr - 4.00 Cr',
    //         'Above 4.00 Cr',
    //       ].includes(this.bookConsultationForm2.value.homeValue)
    //     ) {
    //       formdt.append('lead_status', 'not_attempted');
    //     } else if (
    //       ['Delhi NCR', 'Ghaziabad', 'Faridabad'].includes(
    //         this.bookConsultationForm1.value.city
    //       ) &&
    //       [
    //         '1 - 1.25 Cr',
    //         '1.26 - 1.75 Cr',
    //         '1.76 - 2.50 Cr',
    //         '2.5 Cr - 4.00 Cr',
    //         'Above 4.00 Cr',
    //       ].includes(this.bookConsultationForm2.value.homeValue)
    //     ) {
    //       formdt.append('lead_status', 'not_attempted');
    //     } else if (
    //       ['Bangalore', 'Pune'].includes(
    //         this.bookConsultationForm1.value.city
    //       ) &&
    //       [
    //         '30 - 50 Lacs',
    //         '50 - 70 Lacs',
    //         '70 - 99 Lacs',
    //         '1 - 1.25 Cr',
    //         '1.26 - 1.75 Cr',
    //         '1.76 - 2.50 Cr',
    //         '2.5 Cr - 4.00 Cr',
    //         'Above 4.00 Cr',
    //       ].includes(this.bookConsultationForm2.value.homeValue)
    //     ) {
    //       formdt.append('lead_status', 'not_attempted');
    //     } else {
    //       formdt.append('lead_status', 'not_attempted');
    //     }
    //   } else {
    //     if (this.bookConsultationForm3.value.yourBudge != 'Less than 1.5 Lac') {
    //       if (
    //         ['Mumbai'].includes(this.bookConsultationForm1.value.city) &&
    //         [
    //           '70 - 99 Lacs',
    //           '1 - 1.25 Cr',
    //           '1.26 - 1.75 Cr',
    //           '1.76 - 2.50 Cr',
    //           '2.5 Cr - 4.00 Cr',
    //           'Above 4.00 Cr',
    //         ].includes(this.bookConsultationForm2.value.homeValue)
    //       ) {
    //         formdt.append('lead_status', 'not_attempted');
    //       } else if (
    //         ['Delhi NCR', 'Ghaziabad', 'Faridabad'].includes(
    //           this.bookConsultationForm1.value.city
    //         ) &&
    //         [
    //           '1 - 1.25 Cr',
    //           '1.26 - 1.75 Cr',
    //           '1.76 - 2.50 Cr',
    //           '2.5 Cr - 4.00 Cr',
    //           'Above 4.00 Cr',
    //         ].includes(this.bookConsultationForm2.value.homeValue)
    //       ) {
    //         formdt.append('lead_status', 'not_attempted');
    //       } else if (
    //         [
    //           'Bangalore',
    //           'Pune',
    //           'Chennai',
    //           'Aurangabad',
    //           'Ahmednagar',
    //           'Kochi',
    //         ].includes(this.bookConsultationForm1.value.city) &&
    //         [
    //           '30 - 50 Lacs',
    //           '50 - 70 Lacs',
    //           '70 - 99 Lacs',
    //           '1 - 1.25 Cr',
    //           '1.26 - 1.75 Cr',
    //           '1.76 - 2.50 Cr',
    //           '2.5 Cr - 4.00 Cr',
    //           'Above 4.00 Cr',
    //         ].includes(this.bookConsultationForm2.value.homeValue)
    //       ) {
    //         formdt.append('lead_status', 'not_attempted');
    //       } else {
    //         formdt.append('lead_status', 'not_attempted');
    //       }
    //     }
    //   }
    // } else {
    //   formdt.delete('lead_status');
    //   formdt.append('lead_status', 'not_attempted');
    // }
    // //Lead status data end
    // formdt.append(
    //   'possession_status',
    //   this.bookConsultationForm3.value.possessionProperty_date ? 'new' : ''
    // );
    // formdt.append('from_fasttrack_page', 'true');
    // formdt.append('from_fasttrack_page', 'true');
    // if (this.fileupload) {
    //   formdt.append('lead_floorplan[]', this.fileupload, this.fileupload.name);
    // } else {
    //   formdt.append('lead_floorplan[]', '');
    // }

    // formdt.append('lead_questionaire_items_attributes', 'null');

    // console.log(formdt,'------------->><<>><<>><<>><<>>')

    // this.apartmentservice
    //   .updateleadstatus(this.leadData.lead.id, formdt)
    //   .subscribe(
    //     (res) => {
    //       this.loader = false;
    //       this.updateLeadData = res;
    //       this.modal.close(res);
    //       this.toastr.success(
    //         'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours'
    //       );
    //     },
    //     (error) => {
    //       this.toastr.error(error.error.message);
    //     }
    //   );

    let leadData:any = [
      // form 1 fields
      { "Attribute": "FirstName", "Value":  this.bookConsultationForm1.value.fullName },
      { "Attribute": "mx_Lead_Name", "Value": this.bookConsultationForm1.value.fullName },
      { "Attribute": "Phone", "Value": this.bookConsultationForm1.value.mobileNumber },
      { "Attribute": "EmailAddress", "Value": this.bookConsultationForm1.value.emailAddress },
      { "Attribute": "mx_Society_Name", "Value": this.bookConsultationForm1.value.propertyName ? this.bookConsultationForm1.value.propertyName :'' },
      { "Attribute": "mx_Location", "Value": this.bookConsultationForm1.value.Locality },
      { "Attribute": "mx_City", "Value": this.bookConsultationForm1.value.city ? this.bookConsultationForm1.value.city : this.marathonCityValue },
      { "Attribute": "mx_Zip", "Value": this.bookConsultationForm1.value.pincode },
      
      // form 2 fields
      { "Attribute": "mx_Scope_of_Work", "Value": this.bookConsultationForm2.value.scopeOfWork },
      { "Attribute": "mx_Type_of_Accommodation", "Value": this.bookConsultationForm2.value.accomandationType },
      { "Attribute": "mx_Home_Value", "Value": this.bookConsultationForm2.value.homeValue ? this.bookConsultationForm2.value.homeValue : '' },
      { "Attribute": "mx_Floor_plan_status", "Value": this.bookConsultationForm2.value.doYouHaveFloorPlan  },
      //file is pending
      
      
      // form 3 fields
      { "Attribute": "mx_What_are_you_looking_for", "Value": this.bookConsultationForm3.value.lookingFor },
      { "Attribute": "mx_When_do_you_get_the_possession_of_the_property", "Value": this.bookConsultationForm3.value.possessionProperty_date ? 'Awaiting Possession'  : 'Possession Taken' },
      { "Attribute": "mx_When_do_you_want_to_start_the_project", "Value": this.bookConsultationForm3.value.startProject_date },
      { "Attribute": "mx1_Budget_Value", "Value": this.bookConsultationForm3.value.yourBudge }, // it needs to be an number
      // When do you want to start the project? needs to add
      // mx_When_do_you_want_to_start_the_project
      // 

      // form 4 fields
      { "Attribute": "mx_Do_you_have_a_home_loan_against_the_property", "Value": this.bookConsultationForm4.value.existingHomeLone == 'Yes' ? true : false},
      { "Attribute": "mx_Financial_Assisstance", "Value": this.bookConsultationForm4.value.financialassistance == 'Yes' ? true : false},
      { "Attribute": "mx_Purpose_Of_Property", "Value": this.bookConsultationForm4.value.purposeProperty},

      { "Attribute": "Notes", "Value": this.bookConsultationForm4.value.tellMeAboutYourself || '' },
      // Would you like any financial assistance?
      // Purpose Of Property
      // 
      
      // Extra keys
      { "Attribute": "Source", "Value": "website" },
      { "Attribute": "SourceCampaign", "Value": "" },
      { "Attribute": "mx_UTM_Medium", "Value": "" },
      { "Attribute": "mx_UTM_Term", "Value": "" },
      { "Attribute": "mx_UTM_Content", "Value": "" },
      { "Attribute": "mx_Apartment_ID", "Value": this.AidApartmentID ? this.AidApartmentID : '' },
      { "Attribute": "mx_Is_MarketingPage", "Value": true },
      
      // Appartment Id key needs
      // MarketingPage
      // 
 
      //   "Attribute": "FinancialSolutionRequired", 
      //   "Value": this.bookConsultationForm4.value.existingHomeLone === 'Yes' ? this.bookConsultationForm4.value.financialassistance : null 
      // },
      
      
    ];
    if(this.determineLeadStatus()){
      leadData.push({ 
        "Attribute": "mx_Lead_Status", 
        "Value":  this.determineLeadStatus()
      })
    }
    this.apartmentservice
      .createBookConsultation({'leads': leadData})
      .subscribe(
        (res) => {
          this.loader = false;
          this.updateLeadData = res;
          this.modal.close(res);
          this.toastr.success(
            'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours'
          );
        },
        (error) => {
          this.loader = false;
          this.toastr.error(error.error.message);
        }
      );
  }

  determineLeadStatus(): string {
    // Check if possession and start project dates are provided
    if (this.bookConsultationForm3.value.possessionProperty_date && this.bookConsultationForm3.value.startProject_date) {
      
      // Check if the scope of work is "Full Home Interiors"
      if (this.bookConsultationForm1.value.scopeOfWork === 'Full Home Interiors') {
        
        // Check for specific cities and home values
        if (['Mumbai'].includes(this.bookConsultationForm1.value.city) && 
            ['70 - 99 Lacs', '1 - 1.25 Cr', '1.26 - 1.75 Cr', '1.76 - 2.50 Cr', '2.5 Cr - 4.00 Cr', 'Above 4.00 Cr'].includes(this.bookConsultationForm2.value.homeValue)) {
          return 'not_attempted';
        }
        
        if (['Bangalore'].includes(this.bookConsultationForm1.value.city) && 
            ['40 - 69 Lacs', '70 - 99 Lacs', '1 - 1.25 Cr', '1.26 - 1.75 Cr', '1.76 - 2.50 Cr', '2.5 Cr - 4.00 Cr', 'Above 4.00 Cr'].includes(this.bookConsultationForm2.value.homeValue)) {
          return 'not_attempted';
        }
        
        if (['Pune'].includes(this.bookConsultationForm1.value.city) && 
            ['70 - 99 Lacs', '1 - 1.25 Cr', '1.26 - 1.75 Cr', '1.76 - 2.50 Cr', '2.5 Cr - 4.00 Cr', 'Above 4.00 Cr'].includes(this.bookConsultationForm2.value.homeValue)) {
          return 'not_attempted';
        }
        
        if (['Hyderabad'].includes(this.bookConsultationForm1.value.city) && 
            ['40 - 69 Lacs', '70 - 99 Lacs', '1 - 1.25 Cr', '1.26 - 1.75 Cr', '1.76 - 2.50 Cr', '2.5 Cr - 4.00 Cr', 'Above 4.00 Cr'].includes(this.bookConsultationForm2.value.homeValue)) {
          return 'not_attempted';
        }
        
        if (['Chennai'].includes(this.bookConsultationForm1.value.city) && 
            ['40 - 69 Lacs', '70 - 99 Lacs', '1 - 1.25 Cr', '1.26 - 1.75 Cr', '1.76 - 2.50 Cr', '2.5 Cr - 4.00 Cr', 'Above 4.00 Cr'].includes(this.bookConsultationForm2.value.homeValue)) {
          return 'not_attempted';
        }
        
        if (['Delhi NCR'].includes(this.bookConsultationForm1.value.city) && 
            ['40 - 69 Lacs', '70 - 99 Lacs', '1 - 1.25 Cr', '1.26 - 1.75 Cr', '1.76 - 2.50 Cr', '2.5 Cr - 4.00 Cr', 'Above 4.00 Cr'].includes(this.bookConsultationForm2.value.homeValue)) {
          return 'not_attempted';
        }
      }
      
      // Check for Modular work
      if (this.bookConsultationForm1.value.scopeOfWork === 'Modular') {
        
        // Check for specific cities and home values
        if (['Mumbai', 'Bangalore'].includes(this.bookConsultationForm1.value.city) && 
            ['70 - 99 Lacs', '1 - 1.25 Cr', '1.26 - 1.75 Cr', '1.76 - 2.50 Cr', '2.5 Cr - 4.00 Cr', 'Above 4.00 Cr'].includes(this.bookConsultationForm2.value.homeValue)) {
          return 'not_attempted';
        }
      }
      
      // Additional conditions can be added here as required
      
    } else {
      // If possession or start project dates are not provided
      return 'not_attempted';
    }
    
    // Default case if none of the above conditions match
    return 'not_attempted';
  }
  

  cancelTwo() {
    this.modal.close();
  }
  nextTwo() {}
  cancelThree() {
    this.modal.close();
  }
  nextThree() {}
  cancelFour() {
    this.modal.close();
  }
  submit() {}

  // ------------ Manually data for dropdowns (dont change any values)------------

  getNext12MonthNamesWithYear() {
    var now = new Date();
    var month = now.getMonth();
    var year = now.getFullYear();
    var names = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.possession_date = [];
    for (var i = 0; i < 13; ++i) {
      this.possession_date.push(names[month] + ' ' + year);
      if (++month === 12) {
        month = 0;
        ++year;
      }
    }
    this.project_start_date = this.possession_date;
    return this.possession_date;
  }

  CityData: any = [
    { id: 0, cityname: 'Delhi NCR' },
    { id: 1, cityname: 'Mumbai' },
    { id: 2, cityname: 'Pune' },
    { id: 3, cityname: 'Bangalore' },
    { id: 4, cityname: 'Chennai' },
    { id: 5, cityname: 'Chandigarh' },
    { id: 6, cityname: 'Ahmedabad' },
    { id: 7, cityname: 'Kochi' },
    { id: 8, cityname: 'Aurangabad' },
    { id: 9, cityname: 'Trivandrum' },
    { id: 10, cityname: 'Calicut' },
    { id: 11, cityname: 'Faridabad' },
    { id: 12, cityname: 'Hyderabad' },
    { id: 13, cityname: 'Jaipur' },
    { id: 14, cityname: 'Navi Mumbai' }

  ];
  PurposeOfProperty: any = [
    { id: 0, value: 'Own Stay' },
    { id: 1, value: 'Rental' },
    { id: 2, value: 'Not Disclosed' },
  ];

  ScopeWorkData: any = [
    { id: 0, scopeworkname: 'Modular Kitchen' },
    { id: 1, scopeworkname: 'Furniture & MTO (Includes False Ceiling/Painting)' },
    { id: 2, scopeworkname:  'FHI (Includes Services)' },
    
  ];

  AccomandationTypeData: any = [
    { id: 0, accomandationtname: '1RK' },
    { id: 1, accomandationtname: '1BHK' },
    { id: 2, accomandationtname: '1.5BHK' },
    { id: 3, accomandationtname: '2BHK' },
    { id: 4, accomandationtname: '2.5BHK' },
    { id: 5, accomandationtname: '3BHK' },
    { id: 6, accomandationtname: '3.5BHK' },
    { id: 7, accomandationtname: '4BHK' },
    { id: 8, accomandationtname: '4.5BHK' },
    { id: 9, accomandationtname: '5BHK' },
    { id: 10, accomandationtname: 'Villa' },
    { id: 11, accomandationtname: 'Bungalow' },
  ];

  HomeValueData: any = [
    { id: 0, homevalue: 'Less than 30 Lacs' },
    { id: 1, homevalue: '30 - 50 Lacs' },
    { id: 2, homevalue: '50 - 70 Lacs' },
    { id: 3, homevalue: '70 - 99 Lacs' },
    { id: 4, homevalue: '1 - 1.25 Cr' },
    { id: 5, homevalue: '1.26 - 1.75 Cr' },
    { id: 6, homevalue: '1.76 - 2.50 Cr' },
    { id: 7, homevalue: '2.5 Cr - 4.00 Cr' },
    { id: 8, homevalue: 'Above 4.00 Cr' },
  ];

  LookingForData: any = [
    { id: 1, value: 'New (No one has ever stayed in that home)' },
    { id: 1, value: 'Old (Currently staying in the house)' },
  ];

  BudgeValueData: any = [
    { id: 0, budgevalue: 'Less than 1.5 Lacs' },
    { id: 0, budgevalue: '1.5 - 3 Lacs' },
    { id: 0, budgevalue: '3 - 5 Lacs' },
    { id: 0, budgevalue: '5 - 8 Lacs' },
    { id: 0, budgevalue: '8 - 12 Lacs' },
    { id: 0, budgevalue: 'Above 12 Lacs' },
    { id: 0, budgevalue: 'Not Disclosed' },
  ];

  homeloadData: any = [
    { id: 1, value: 'Yes' },
    { id: 2, value: 'No' },
  ];
  financialassistanceData: any = [
    { id: 1, value: 'Yes' },
    { id: 2, value: 'No' },
  ];



}
