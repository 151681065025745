<div class="container-fluid bg-white p-0">
    <div class="row FormSection p-md-5 pt-3 mx-md-4 m-1 mt-2 d-flex justify-content-center">
        <div class="col-12 header pt-md-3 px-4 d-flex justify-content-center align-items-center flex-column">
            <h2 *ngIf="selectedform==1 || selectedform==2">
                 Ready to Start Your Dream Home Interiors Journey?
            </h2>
            <h2 *ngIf="selectedform==3">
                Tell Us a Little Bit About Your Home!
            </h2>
            <h2 *ngIf="selectedform==4">
                Tell Us a Little Bit About Your Preferences
            </h2>
            <p *ngIf="selectedform==4" class="d-md-block d-none">
                One final step before we set up a call with our experts!
            </p>
        </div>

        <div class="col-12 formpointer d-flex justify-content-center p-3">
            <ul class="stepper d-flex justify-content-between">
                <li class="step " (click)="switchform(1)" [ngClass]="selectedform==1 ? 'completed' : ''">
                    <span class="step-number">1</span>
                </li>
                <li style="width:100%">
                    <div style="border: 1px dashed grey;"></div>
                </li>
                <li class="step" (click)="this.GetQuoteForm.valid && switchform(2)" [ngClass]="selectedform==2 ? 'completed' : ''">
                    <span class="step-number">2</span>
                </li>
                <li style="width:100%">
                    <div style="border: 1px dashed grey;"></div>
                </li>
                <li class="step" (click)="this.GetQuoteForm.valid && switchform(3)" [ngClass]="selectedform==3 ? 'completed' : ''">
                    <span class="step-number">3</span>
                </li>
                <li style="width:100%">
                    <div style="border: 1px dashed grey;"></div>
                </li>
                <li class="step" (click)="this.GetQuoteForm.valid && switchform(4)" [ngClass]="selectedform==4 ? 'completed' : ''">
                    <span class="step-number">4</span>
                </li>
            </ul>

        </div>

        <div class="col-12 d-flex justify-content-center p-0">

            <form [formGroup]="GetQuoteForm" (ngSubmit)="DetailsSubmit()">

                <div class="Form1 d-flex justify-content-center" *ngIf="selectedform==1">
                    <div class="row content w-md w-sm-100 w-md d-flex justify-content-center m-0">
                        <div class="col-md-6 form p-4 m-2 m-md-0 ">
                            <h4 class="mb-3">Tell us a little bit about yourself!</h4>
                          
                            <div class="section1">
                                <!-- Full Name -->
                                <div class="mb-4 form-group">
                                    <label for="fullName" class="form-label">Full Name*</label>
                                    <input id="fullName" type="text" formControlName="fullName" class="form-control"
                                        placeholder="Enter your name">
                                    <div *ngIf="( GetQuoteForm.get('fullName')?.invalid && GetQuoteForm.get('fullName')?.touched)"
                                        class="text-danger error">
                                        Full name is required.
                                    </div>
                                </div>

                                <!-- Mobile Number and WhatsApp Updates -->
                                <div class="mb-4 form-group">
                                    <label for="mobileNumber" class="form-label">Mobile Number*</label>
                                    <input id="mobileNumber" type="text" formControlName="mobileNumber"
                                        oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                        class="form-control numbers" placeholder="Enter your mobile number">
                                    <div *ngIf="GetQuoteForm.get('mobileNumber')?.invalid && GetQuoteForm.get('mobileNumber')?.touched"
                                        class="text-danger error">
                                        A valid mobile number is required.
                                    </div>
                                </div>

                                <!-- Email -->
                                <div class="mb-4 form-group">
                                    <label for="email" class="form-label">Email*</label>
                                    <input id="email" type="email" formControlName="email" class="form-control"
                                        placeholder="Enter your email">
                                    <div *ngIf="GetQuoteForm.get('email')?.invalid && GetQuoteForm.get('email')?.touched"
                                        class="text-danger error">
                                        A valid email is required.
                                    </div>
                                </div>

                                <div class="mb-4 form-group">
                                    <label for="society" class="form-label">Society Name*</label>
                                    <div class="w-100" (mouseleave)="hideOptions()" (mouseenter)="showOptions()">
                                        <input type="text" placeholder="Enter society name"
                                            formControlName="property_name" class="form-control text_feild  w-100"
                                            (keyup)="searchSocietyApi($event)">
                                        <ul class="adressSelect"
                                            *ngIf="isApifired && !isSelected && GetQuoteForm.controls['property_name'].value != ''">
                                            <li *ngFor="let option of filteredOptions " (click)="selectOption(option)">
                                                <span class="d-inline-block h-100"><i class="fa fa-map-marker pr-1"
                                                        aria-hidden="true" style="
                                             font-size: 22px;vertical-align: middle; "></i></span> <span
                                                    class="d-inline-block ml-1">{{ option.apartment_name }} {{
                                                    option.address }}</span>
                                            </li>
                                            <li *ngIf="!isSelected">
                                                <span class="d-inline-block h-100">
                                                    <i class="fa fa-map-marker pr-1" aria-hidden="true" style="
                                             font-size: 22px;vertical-align: middle; "></i></span> <span
                                                    class="d-inline-block ml-1"> Could not find your property?
                                                    <button class="submit_btn w-100 mt-3"
                                                        (click)="selectOption('other')">Add Property
                                                        Details</button></span>
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                                <!-- Row for Budget and Pincode -->
                                <div class="row">
                                    
                                    <div class="col-md-6 mb-4 form-group">
                                        <label for="pincode" class="form-label">Property Pincode*</label>
                                        <input id="pincode" type="text" formControlName="pincode" class="form-control numbers"
                                            readonly  placeholder="Pincode">
                                        <div *ngIf="GetQuoteForm.get('pincode')?.invalid && GetQuoteForm.get('pincode')?.touched"
                                            class="text-danger error">
                                            Pincode is required.
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4 form-group">
                                        <label for="budget" class="form-label">Your planned budget?</label>
                                        <select id="budget" formControlName="budget" class="form-select">
                                            <option value="">Select a budget</option>
                                            <option value="10L">Up to 10L</option>
                                            <option value="20L">Up to 20L</option>
                                            <option value="30L">Up to 30L</option>
                                        </select>
                                    </div>

                                </div>

                                <!-- Society Name -->
                                

                                <!-- Submit Button -->
                            </div>
                        </div>
                        <div class="col-12 d-md-none d-block my-3 px-0">
                            <button class="btn-pink uppercase" (click)="moveforward()" style="width: 100%;">
                                Next
                            </button>
                        </div>
                        <div class="col-md-6 col-12 detail p-0 text-center">
                            <div class="p-4">
                                <h4 class="mb-3">Why do we need this information?</h4>
                                <p class="m-0">It'll help our customer service representative offer you a personalised experience
                                    and
                                    ensure smooth communication throughout your journey. <br><br>Providing your pincode
                                    and
                                    society allows us to check if our services are available in your area.<br>
                                </p>
                            </div>
                            <div style="">
                                <img class="img-fluid posterimage d-md-block d-none"
                                    src="assets/Longform/images/Birla Vanya1.webp" alt="">
                            </div>

                        </div>

                    </div>
                </div>

                <div class="Form2 d-flex justify-content-center" *ngIf="selectedform==2">
                    <div class="row content">
                        <div class="col-md-6 form px-5 p-4 m-2 m-md-0">
                            <div class="p-md-5 d-flex flex-column  justify-content-center bhkblock">
                                <h5>What’s the layout?</h5>
                                <button *ngFor="let bhk of bhkOptions" type="button"
                                    class="btn optionsbtn rounded-pill my-2"
                                    [ngClass]="{'active': GetQuoteForm.controls['bhk'].value === bhk}"
                                    (click)="selectBhk(bhk)">
                                    {{ bhk }}
                                </button>
                            </div>
                        </div>
                        <div class="col-12 d-md-none d-block my-3 px-0">
                            <button class="btn-pink uppercase" (click)="moveforward()" style="width: 100%;">
                                Next
                            </button>
                        </div>
                        <div class="col-md-6 col-12 detail p-0 d-flex align-items-center">
                            <div class="p-md-5 p-4 d-flex flex-column  justify-content-cente align-items-center">
                                <h4>Why do we need this information?</h4>
                                <img class="p-5 d-none d-md-block" src="assets/Longform/icons/form2.svg" alt="">
                                <p class="text-center px-md-4 p-2">Our designs are personalised to suit <br>your
                                    lifestyle, optimised to your layouts and reflect your style. Knowing your layout
                                    will help us understand the scale of work.</p>
                                <p class="text-center d-block d-md-none p-2">Providing your city and pin code allows us
                                    to check if our services are available in your area.</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="Form3 d-flex justify-content-center" *ngIf="selectedform==3">
                    <div class="row content">
                        <div class="col-md-6 form px-md-5 p-4 m-2 m-md-0 d-flex">
                            <div class="p-md-3 w-100 d-flex flex-column  justify-content-center">
                                <h5>Do you have the possession of the house?</h5>

                                <button class="btn btn-pink my-2">Yes</button>
                                <div class="form-group">
                                    <label class="pb-1" for="">No, I will receive it in</label>
                                    <div class="row d-flex gap-2">
                                        <div class="col-md col-12 pr-md-0">
                                            <select formControlName="monthReceived" class="form-control mr-md-2 mb-2"  (change)="posessiondate()" >
                                                <option value="" disabled>Month</option>
                                                <option *ngFor="let month of months" [value]="month">{{ month }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md col-12 pl-md-0">
                                            <select formControlName="yearReceived" class="form-control ml-md-2" (change)="posessiondate()">
                                                <option value="" disabled>Year</option>
                                                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3 d-md-block d-none" style="color: #959595;">
                                    ---------------------
                                </div>

                                <div class="form-group">
                                    <label class="pb-1" for="">When would you like to start the project?</label>
                                    <div class="row d-flex gap-1">
                                        <div class="col-md-6 col-12 pr-md-0" >
                                            <select formControlName="monthStart" class="form-control mr-md-2 mb-2" (change)=startdate()>
                                                <option value="" disabled>Month</option>
                                                <option *ngFor="let month of months" [value]="month">{{ month }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-6 col-12 pl-md-0">
                                            <select formControlName="yearStart" class="form-control ml-md-2" (change)=startdate()>
                                                <option value="" disabled>Year</option>
                                                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="col-12 d-md-none d-block my-3 px-0">
                            <button class="btn-pink uppercase" (click)="moveforward()" style="width: 100%;">
                                Next
                            </button>
                        </div>
                        <div class="col-md-6 col-12 detail p-0 d-flex align-items-center">
                            <div class="p-md-5 p-4 d-flex flex-column  justify-content-cente align-items-center">
                                <h4>Why do we need this information?</h4>
                                <img class="p-5 d-none d-md-block" src="assets/Longform/icons/form3.svg" alt="">
                                <p class="text-center px-4 p-4">Delayed possession can greatly affect the project’s
                                    timeline and costs.
                                    By knowing your possession date, we can recommend the best time to start designing
                                    your home and
                                    offer tips to help you avoid price increases.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="Form4 d-flex justify-content-center" *ngIf="selectedform==4">
                    <div class="row content">
                        <div class="col-md-6 form px-md-5 p-4 m-2 m-md-0">
                            <div class="form-group row m-0">
                                <label class="px-0 pb-2" for="">What kind of interior solutions would you like?</label>
                                <div class="col-12 p-0 d-flex flex-column gap-2">
                                    <div class="row d-flex  m-0">
                                        <button class="col mr-2 mb-2 rounded-pill optionsbtn"
                                            [ngClass]="{'active': GetQuoteForm.controls['interior'].value === 'Full home interiors'}"
                                            (click)="selectinterior('Full home interiors')">
                                            Full home interiors
                                        </button>
                                        <button class="col ml-2 mb-2 rounded-pill optionsbtn"
                                            [ngClass]="{'active': GetQuoteForm.controls['interior'].value === 'Modular kitchen'}"
                                            (click)="selectinterior('Modular kitchen')">
                                            Modular kitchen
                                        </button>
                                    </div>
                                    <div class="row d-flex  m-0">
                                        <button class="col mr-2  rounded-pill optionsbtn"
                                            [ngClass]="{'active': GetQuoteForm.controls['interior'].value === 'Full home Furniture'}"
                                            (click)="selectinterior('Full home Furniture')">
                                            Full home Furniture
                                        </button>
                                        <button class="col ml-2 rounded-pill optionsbtn"
                                            [ngClass]="{'active': GetQuoteForm.controls['interior'].value === 'Modular Furniture'}"
                                            (click)="selectinterior('Modular Furniture')">
                                            Modular Furniture
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="my-1 " style="color: #959595;">
                                ---------------------
                            </div>

                            <div class="form-group">
                                <label for="">Which style appeals to you the most?</label>

                                <div class="style-options-container">
                                    <div *ngFor="let option of styleOptions" class="style-option">
                                        <label (click)="selectStyle(option.id)"
                                            [class.selected]="selectedStyle === option.id">
                                            <img [src]="option.imageUrl" [alt]="option.name" class="option-image">
                                            <div class="option-label">{{ option.name }}</div>
                                        </label>
                                    </div>
                                </div>



                            </div>

                            <button class="btn btn-pink w-100 uppercase request">Request a call back</button>
                            <p class="footer my-1">By submitting this form, you agree to the  <a>privacy
                                    policy</a> & <a>terms and conditions</a></p>



                        </div>

                        <div class="col-md-6 col-12 detail p-0 text-center d-flex align-items-center">
                            <div class="p-4">
                                <h4>Why do we need this information?</h4>

                                <div class="row py-md-4 py-2 d-flex">
                                    <div class="col-3 interiortype">
                                        <h4>Modular Kitchen</h4>
                                        <p>Trolleys, Drawers, Wall Units, Loft Units, Cabinets and Accessories</p>
                                    </div>
                                    <div class="col-3 interiortype">
                                        <h4>Modular Furniture</h4>
                                        <p>Modular Kitchen</p>
                                        <span style="font-size: 36px;font-weight: 400;">+</span>
                                        <p>Wardrobes, Beds, Side Tables, TV Unit, Study and Multipurpose Storage Units
                                        </p>
                                    </div>
                                    <div class="col-3 interiortype">
                                        <h4>Full Home Furniture</h4>
                                        <p>Modular Furniture</p>
                                        <span style="font-size: 36px;font-weight: 400;">+</span>

                                        <p> Loose Furniture, Panelling and Modular Kitchen</p>
                                    </div>
                                    <div class="col-3 interiortype">
                                        <h4>Full Home Interiors</h4>
                                        <p>Full Home Furniture</p>
                                        <span style="font-size: 36px;font-weight: 400;">+</span>
                                        <p>Civil and Service Work, Panelling, Painting, False Ceiling</p>
                                    </div>
                                </div>

                                <p>Our designs and quotes are personalized. Understanding your requirements allows our
                                    experts to recommend design <br>solutions that perfectly match your needs.</p>
                            </div>


                        </div>

                    </div>
                </div>

            </form>

        </div>

        <div class="col-12 navigation p-4 d-md-flex d-none gap-2 align-items-center justify-content-center">
            <button *ngIf="selectedform==1" class="navleft mr-2"
                (click)="startover()">CANCEL</button>
            <button *ngIf=" selectedform==2 || selectedform==3 || selectedform==4" class="navleft mr-2"
                (click)="movebackward()">BACK</button>
            <button *ngIf="selectedform==1 || selectedform==2 || selectedform==3" class="navright ml-2"
                 (click)="moveforward()">
                NEXT
            </button>
            <button *ngIf="selectedform==4" class="navright ml-2">CANCEL</button>
        </div>

    </div>
</div>



<div id="OtherprojectName2" class="modal" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 50rem !important;">
        <!-- Modal content-->
        <div class="modal-content p-1">
            <div class="modal-header">
                <h6 class="modal-title w-100 text-center"> Add Society/Building/Project </h6>
                <button data-dismiss="modal" type="button" class="close"><i class="fa fa-close"></i></button>
            </div>
            <div class="modal-body ">
                <div class="col-12 row">
                    <div class="form-check d-flex col-12 ">
                        <label class="customLabel mt-2 col-6">Society/Building/Project Name :<span
                                class="text-danger hideAsteriskIcon">*</span></label>
                        <input type="text" class="form-control col-6" [(ngModel)]="projectName2"
                            placeholder="Enter the Project name">
                    </div>
                    <div class="form-check  d-flex col-12">
                        <label class="customLabel mt-2 col-6 ">Developer Name :<span
                                class="text-danger hideAsteriskIcon">*</span></label>
                        <input type="text" class="form-control col-6" placeholder="Enter the Developer name"
                            [(ngModel)]="DeveloperName2">
                    </div>
                    <div class="form-check d-flex col-12">
                        <label class="customLabel mt-2  col-6">Full Address :<span
                                class="text-danger hideAsteriskIcon">*</span></label>
                        <input [(ngModel)]="FullAdress2" type="text" class="form-control" ngx-google-places-autocomplete
                            #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
                            placeholder="Enter the Full Address">

                    </div>
                    <div class="form-check  d-flex col-12">
                        <label class="customLabel mt-2 col-6">City :<span class="text-danger ">*</span></label>
                        <input [(ngModel)]="citynamePro2" readonly type="text" class="form-control col-6">
                    </div>

                    <div class="form-check d-flex col-12">
                        <label class="customLabel mt-2 col-6">Pincode :<span class="text-danger ">*</span></label>
                        <input [(ngModel)]="pincodePro2" type="text" class="form-control col-6">

                    </div>
                </div>
                <div class="col-12 mt-2 d-flex justify-content-center">

                    <button class="btn btn-pink px-3 py-2"
                        [disabled]="projectName2 == '' || DeveloperName2 == '' ||  FullAdress2 == '' || pincodePro2 == ''   "
                        (click)="Submitapart()">Submit
                    </button>

                </div>
            </div>
        </div>
    </div>
</div>