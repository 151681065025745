<div class="container-fluid">
    <div class="row p-md-5 p-4">
        <div class="col-12 d-flex flex-column justify-content-center header">
            <h2>WHY CHOOSE ARRIVAE?</h2>
            <p>Your needs and requirements are at the heart of everything we do</p>

        </div>
        <div class="col-12 px-5 pt-md-5">
            <div class="row pt-3 pt-md-0">
                <div class="col-6 p-0 p-md-2 col-sm-4 col-md d-flex flex-column align-items-center feature" *ngFor="let item of data; let i = index">
                    <img class="mb-3" src={{item.image}} alt="">
                    <span class="pb-2">{{item.title}}</span>
                    <p>{{item.content}}</p>
                </div>
            </div>
        </div>
    </div>
</div>