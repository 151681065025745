import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApartmentService } from 'src/app/apartment/apartment.service';
declare var $: any;
@Component({
  selector: 'app-consultation-form',
  templateUrl: './consultation-form.component.html',
  styleUrls: ['./consultation-form.component.scss']
})



export class ConsultationFormComponent implements OnInit {


  GetQuoteForm: FormGroup;
  isApifired: boolean = false;

  filteredOptions: any[] = [];
  isSelected: boolean = false;
  projectName2: any | undefined;
  DeveloperName2: string | undefined;
  FullAdress2: string | undefined;
  citynamePro2: string | undefined;
  pincodePro2: string | undefined;
  AidApartmentID: string | undefined;
  othersShow: boolean | undefined;
  Typeselect: string | undefined;
  formattedaddress: any;
  PropertyName: any;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private apartmentservice: ApartmentService) {

    this.GetQuoteForm = this.fb.group({
        fullName: ['', Validators.required],
        mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
        email: ['', [Validators.required, Validators.email]],
        whatsappUpdates: [false],

        pincode: ['', Validators.required],
        society: ['', Validators.required],
        property_name: [''],
        city:[''],


      });
  }



  ngOnInit(): void {
  }



  hideOptions() {
    this.isApifired = false;
  }
  showOptions() {
    this.isApifired = true;
  }
  searchSocietyApi($event: any) {

    console.log(this.isApifired, this.isSelected, this.GetQuoteForm.controls['society'].value, this.GetQuoteForm.controls['society'].value != '')
    console.log(this.GetQuoteForm.value);

    this.othersShow = true;
    this.isApifired = true;
    this.Typeselect = '';
    this.isSelected = false;
    let searchWord = $event.target.value;
    this.apartmentservice.getSocietyWebapi(searchWord).subscribe(
      (res: any) => {
        this.filteredOptions = res.result;
        console.log(this.filteredOptions);
      },
      (err) => {
        this.filteredOptions = [];
      }
    );
    console.log(this.isApifired, this.isSelected, this.GetQuoteForm.controls['fullName'].value, this.GetQuoteForm.controls['society'].value != '')
  }

  selectOption(address: any) {
    this.filteredOptions = [];
    this.isSelected = true;
    if (address == 'other') {
      $('#OtherprojectName2').modal('show');
      this.projectName2 = this.GetQuoteForm.controls['property_name'].value;
      console.log(
        this.projectName2,
        this.GetQuoteForm.controls['property_name'].value,
        'test'
      );
      this.DeveloperName2 = '';
      this.FullAdress2 = '';
      this.citynamePro2 = '';
      this.pincodePro2 = '';
      this.AidApartmentID = '';
    } else {
      this.GetQuoteForm.controls['society'].setValue(address.apartment_name);

      this.projectName2 = this.GetQuoteForm.controls['society'].value;
      let location = this.projectName2 + ',' + address.area_name;
      
      this.GetQuoteForm.controls['city'].setValue(address.city);
      this.GetQuoteForm.controls['pincode'].setValue(address.pincode);
      this.GetQuoteForm.controls['property_name'].setValue(location);

      this.AidApartmentID = address.id;
    }

    console.log(this.GetQuoteForm.value);
  }


  DetailsSubmit() {
    const formattedData: any = {
      leads: [
        { Attribute: 'EmailAddress', Value: this.GetQuoteForm.value.email ?? ""  },
        { Attribute: 'FirstName', Value: this.GetQuoteForm.value.fullName  ?? "" },
        { Attribute: 'mx_Lead_Name', Value: this.GetQuoteForm.value.fullName  ?? "" },
        { Attribute: 'Phone', Value: this.GetQuoteForm.value.mobileNumber  ?? ""  },

        { Attribute: 'mx_Location', Value: this.GetQuoteForm.value.property_name  ?? "" },
        { Attribute: 'mx_Society_Name', Value: this.GetQuoteForm.value.society  ?? "" , },
        { Attribute: 'mx_City', Value: this.GetQuoteForm.value.city ?? ""  },
        { Attribute: 'mx_Zip', Value: this.GetQuoteForm.value.pincode  ?? "" },
        { Attribute: 'Source', Value: 'website' },

        { Attribute: 'SourceCampaign', Value: 'Homepage' },
        { Attribute: 'mx_UTM_Medium', Value: '' },
        { Attribute: 'mx_UTM_Term', Value: '' },
        { Attribute: 'mx_UTM_Content', Value: '' },
        { Attribute: 'mx_Scope_of_Work', Value: '' },
        { Attribute: 'mx_Is_MarketingPage', Value: true },
        { Attribute: 'mx_Apartment_ID', Value: this.AidApartmentID },


      ],
    };
    this.apartmentservice.createBookConsultation(formattedData).subscribe(
      (res) => {
        this.toastr.success(
          'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours'
        );
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  areaname: any;
  Submitapart() {
    $('#OtherprojectName2').modal('hide');
    let area = this.areaname ? this.areaname : this.FullAdress2;
    let location = this.projectName2 + ',' + area;

    this.GetQuoteForm.controls['city'].setValue(this.citynamePro2);
    this.GetQuoteForm.controls['pincode'].setValue(this.pincodePro2);

    this.GetQuoteForm.controls['property_name'].setValue(this.projectName2);
    console.log(this.projectName2, this.GetQuoteForm.value);
    this.GetQuoteForm.controls['apartment'].setValue(location);
    console.log(location, 'location');
    this.CreateApartment();
  }
  CreateApartment() {
    let address = this.GetQuoteForm.controls['apartment'].value;
    const pincodeRegex = /\b\d{6}\b/;
    // Use the match method to find the first match of the regex pattern in the string
    const pincodeMatch = address.match(pincodeRegex);

    // Check if a match is found
    if (pincodeMatch) {
      // Extracted PIN code
      var pincode = pincodeMatch[0];
    } else {
      pincode = '';
    }

    let obj = {
      rera_number: '',
      project_name: this.projectName2,
      full_address: this.FullAdress2,
      project_developer: this.DeveloperName2,
      pincode: this.GetQuoteForm.controls['pincode'].value,
      area_name: this.areaname,
      city: this.GetQuoteForm.controls['city'].value,
      state: '',
    };
    this.apartmentservice.CreateApar(obj).subscribe(
      (Res: any) => {
        this.toastr.success('Apartment Created Successfully');
        this.AidApartmentID = Res.result[0].apartment_id;
      },
      (err) => {
        this.toastr.error(JSON.parse(err['_body']).message);
      }
    );
  }

  handleAddressChange(address: any) {
    this.PropertyName = address.name;
    this.FullAdress2 = this.PropertyName;
    this.formattedaddress = address.name;

    this.formattedaddress = address.name + ',' + address.formatted_address;
    var str = this.formattedaddress;
    var arr = [];
    arr = str.split(',');
    var unique = [];
    for (i = 0; i < arr.length; i++) {
      if (
        i == arr.indexOf(arr[i]) ||
        arr.indexOf(arr[i]) == arr.lastIndexOf(arr[i])
      )
        unique.push(arr[i]);
    }
    this.formattedaddress = unique.join(',');

    console.log(this.formattedaddress);
    console.log(address);
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == 'postal_code') {
          console.log(address.address_components[i].long_name);

          this.pincodePro2 = address.address_components[i].long_name;
        } else {
          this.pincodePro2 = '';
        }

        this.FullAdress2 = this.formattedaddress;
        var areaName = address.address_components.filter((component: any) =>
          component.types.includes('sublocality')
        );
        const concatenatedNames = areaName
          .map((component: any) => component.long_name)
          .join(', ');
        areaName = concatenatedNames;
        this.areaname = areaName ? areaName : this.formattedaddress;
        if (address.address_components[i].types[j] == 'locality') {
          this.GetQuoteForm.controls['city'].setValue(
            address.address_components[i].long_name
          );
          this.citynamePro2 = address.address_components[i].long_name;
          console.log(this.GetQuoteForm.controls['city'].value);
          console.log(address.address_components[i].long_name);
        }
      }
    }
  }

  closeaddApartmentrplanModal() {
    $('#OtherprojectName2').modal('hide');
    this.GetQuoteForm.controls['pincode'].setValue('');
    this.GetQuoteForm.controls['apartment'].setValue('');
    this.GetQuoteForm.controls['property_name'].setValue('');
  }

}
