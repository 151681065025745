import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare function sessionstart(): any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'arrivae';
  window=window;
constructor(private router: Router){}

  ngOnInit() {
  //   this.router.events.subscribe((evt: any) => {
  //     if (!(evt instanceof NavigationEnd)) {
  //         return;
  //     }
  //     window.scrollTo(0, 0)
  // });
 
  
  
  const url2 = window.location.href; 
  const boqurl = window.location.href
  if(boqurl.split('=').length > 1){
    localStorage.clear();
  
  }
  if(boqurl.split('/')[3].split('?')[0]  === 'customer_magic_login_web_boq' ){    
    localStorage.setItem("boqLogin" , boqurl)
  }else {
    
    localStorage.setItem("loginuser",url2);
  }
  

  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
