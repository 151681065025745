<div class="m-1 m-md-0">
<div class="container-fluid p-md-5" style="background: #F4F4F4;">
    <div class="row p-md-5 p-3 mt-0 d-flex justify-content-around">
        <div class="col-md-5 col p-0 d-flex flex-column align-items-md-end mr-1 mr-md-0 ">
            <h2 class="d-md-none pb-3">Say <br>goodbye to</h2>

            <div class="content px-md-5 p-2 d-flex flex-column justify-content-center ">
                <img class="pt-3 pt-md-0" src="../../../assets/shortform/images/Clutter-bro 1.webp" alt="">
                <img class="icons" src="../../../assets/shortform/icons/2szsd.svg" alt="">

                <h2 class="d-md-block d-none py-md-4">Say goodbye to</h2>

                <div class="pb-4"></div>

                <div class="detail row d-flex justify-content-center align-items-center pb-4" *ngFor="let item of variations">
                    <div class="col-md-3 col-12 d-flex justify-content-center">
                        <img src={{item.image}} alt="">
                    </div>
                    <div class="col-md-7 col-12 d-flex justify-content-md-start justify-content-center">
                        <p class="m-0 mt-md-0 mt-2">{{item.descript}}</p>

                    </div>
                </div>




            </div>

        </div>

        <div class="col-md-1 d-md-block d-none" style="border-right:2px solid #D9D9D9;">

        </div>
        <div class="col-md-1 d-md-block d-none" >

        </div>

        <div class="col-md-5 col p-0 d-flex flex-column align-items-md-start ml-1 ml-md-0">
            <h2 class="d-md-none pb-3">What <br>do we offer?</h2>

            <div class="content px-md-5 p-2 d-flex flex-column justify-content-center ">
                <img class="pt-3 pt-md-0" src="../../../assets/shortform/images/Interior design-bro 1.webp" alt="">
                <img class="icons" src="../../../assets/shortform/icons/1qwaw.svg" alt="">

                <h2 class="d-md-block d-none py-md-4">What do we offer?</h2>

                <div class="pb-4"></div>

                <div class="detail row d-flex justify-content-center align-items-center pb-4" *ngFor="let item of variations2">
                    <div class="col-md-3 col-12 d-flex justify-content-center">
                        <img src={{item.image}} alt="">
                    </div>
                    <div class="col-md-7 col-12 d-flex justify-content-start">
                        <p class="m-0 mt-md-0 mt-2">{{item.descript}}</p>

                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row d-flex justify-content-center pb-4">
        <button class="btn btn-pink expertbtn rounded-md-pill uppercase">
            Talk to our experts!
        </button>
    </div>
</div>
</div>