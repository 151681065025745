<div class="container-fluid p-md-5 mt-md-3">
    <div class="row px-md-5 mx-md-5">
        <div class="header col-12 d-flex flex-column justify-content-md-start justify-content-start p-md-0 px-5 pt-5">
            <h4 class="uppercase"><span  class="pb-2 custom-border">13500+ Happy</span> Homes Delivered Across The Country</h4>
            <p class="my-md-3">Our happy customers’ dream home journeys</p>
        </div>
        <div class="col-12 videos mt-md-5 mt-3 p-0 p-md-2">
            <div class="desktop row d-md-block d-none">
                <div class=" d-flex flex-wrap justify-content-between">
                    <div *ngFor="let option of videoslist" class="style-option">
                        <div style="position: relative;">
                        <img [src]="option.image" [alt]="option.title" class="option-image" >
                        <img class="playbtn cursorPointer" src="assets/shortform/icons/Play icon 1.svg" alt="" (click)="openVideoModal(option.url)">
                        </div>
                        <div class="option-label">{{ option.title }}</div>
                        <p class="option-location">{{option.location}}</p>
                    </div>
                  </div>

            </div>


                 <div class="mobile d-md-none d-block">

                    <div class="w-100 cursorPointer videoposter"  *ngIf="!playthevideo">
                        <img class="poster" src="../../../assets/shortform/images/video16.webp">
                        <img class="img-fluid playbtn cursorPointer" (click)="mobilevideoPlayIn(this.id)" style=" height: 20%;" src="../../../assets/shortform/icons/Play icon 1.svg"/>
                    </div>             
             
                   <div class="w-100" *ngIf="playthevideo">
                    <youtube-player
                        [videoId]="id"
                        [width]="videoWidth"
                        (ready)="savePlayer($event)"
                        (change)="onStateChange($event)"
                        [playerVars]="playerVars"
                    ></youtube-player>
                   </div>

                   <div  class="videoThumbnails pt-2">
                      <div class="row w-100" slickContainer #slickController="slick" [slickConfig]="config">
                        <ng-container *ngFor="let option of videoslist">
                         <div slickItem class="col-3 px-1" >
                            <div class="w-100 cursorPointer videoposter"  >
                                <img class="poster" src={{option.image}}>
                                <img class="img-fluid playbtn" (click)="mobilevideoPlayIn(option.url)" style=" height: 20%;" src="../../../assets/shortform/icons/Play icon 1.svg"/>
                            </div>                             
                            <div class="d-flex flex-column justify-content-center">
                                 <p class="option-label mb-0">{{option.title}}</p>
                                 <p class="option-location">{{option.location}}</p>
                            </div>
                         </div>
                         </ng-container>

                      </div> 
                      
                   </div>


                
            </div>

            <div class="d-flex justify-content-center pb-3">
                <button type="button " class="btn startjourney btn-pink uppercase">
                   Start your dream home journey today!
                 </button>
             </div>

        </div>
</div>




<!-- Modal box -->




  <div *ngIf="isModalOpen" class="modal fade" id="Youtubevideoplayer" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 600px;">
      <div class="modal-content">
        <div class="modal-header py-0 " style="border-bottom: none;">
          <button type="button" class="close iconbox" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="height: 325px;">
            <youtube-player
            [videoId]="id"
            [width]="videoWidth"
            [height]="videoHeight"
            (ready)="savePlayer($event)"
            (change)="onStateChange($event)"
            [playerVars]="playerVars"
        ></youtube-player>
        </div>

      </div>
    </div>
  </div>